/* mobile devices */
@media only screen and (max-width: 639px) {
    .myfamily-container {
        display: flex;
        justify-content: center ;
        margin: auto;
        padding-right: 20px ;
        padding-left: 20px ;
        /* width: 100%; */
      }
      .myfamily-edit-form{
        padding: 0px !important;
        min-width: 280px !important;
        max-width: 380px !important;
      }
      .btn-width{
        text-transform: capitalize !important;
        color: #ff3535 !important;
        height: 40px !important;
        border: 1px solid #ff3535 !important;
        max-width: 100px !important;
      }
      .btn-holder{
        margin-top: 20px !important;
        margin-bottom: 30px !important;
      }
      .button-mobile-view{
        gap: 20px !important;
      }
      .relationship-dropdown .MuiSelect-select.MuiInputBase-input{
        height: 45px !important;
        padding: 10px 15px !important;
        box-sizing: border-box;
      }
      .family-edit-form-align{
        margin: 20px 0px;
      }
      .dialog-box .MuiDialog-paper{
        width: 90% !important;
      }
      .choose-avatar-dialog{
        margin: 0;
        display:flex;
        flex-direction:column
      }
      .myfamily-card{
        /* width: 100% !important; */
      }
      .settings-profile-add-dialog-content{
        padding: 20px 21px 40px 21px !important;
        overflow-y: auto;
      }
}

/* tablet devices */
@media only screen and (min-width: 768px) and (max-width: 999px) {
    .myfamily-container {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
      }
      .btn-width{
        text-transform: capitalize !important;
        color: #ff3535 !important;
        height: 40px !important;
        min-width: 170px !important;
        border: 1px solid #ff3535 !important;
      }
      /* .myfamily-edit-form{
        min-width: 290px !important;
        max-width: 380px !important;
      } */
      .button-mobile-view{
        gap: 40px !important;
      }
      .family-edit-form-align{
        margin: 20px 0px;
      }
      .choose-avatar-dialog{
        margin: 0;
        height: 100%;
        display:flex;
        flex-direction:column
      }
      
      .myfamily-card{
        min-width: 246;
      }
      .settings-profile-add-dialog-content{
        margin-bottom: 20px;
        padding: 20px 26px 20px 26px !important;
        overflow-y: auto;
    
      }
}

/*laptop devices */
@media only screen and (min-width: 1000px) {
    .myfamily-container {
        margin-left: auto;
        margin-right: auto;
        max-width: 95%;
        padding-left: 0;
        padding-right: 0;
        width: 95%;
      }
      .btn-width{
        text-transform: capitalize !important;
        color: #ff3535 !important;
        height: 40px !important;
        min-width: 170px !important;
        border: 1px solid #ff3535 !important;
      }
      .button-mobile-view{
        gap: 40px !important;
      }
      .family-edit-form-align{
        margin: 20px auto;
      }
      .choose-avatar-dialog{
        margin: 0;
        height: 100%;
        display:flex;
        flex-direction: row;
      }
      
      .myfamily-card{
        min-width: 246;
      }
      .settings-profile-add-dialog-content{
        padding: 20px 24px 5px 31px !important;
        margin-bottom: 0px;
        overflow-y: auto;
      }
}

/* large devices */
@media only screen and (min-width: 1200px) {

    .myfamily-container {
        margin-left: auto;
        margin-right: auto;
        padding-right: 15px;
        padding-left: 15px;
        max-width: 95%;
        width: 1160px;
      }
      .btn-width{
        text-transform: capitalize !important;
        color: #ff3535 !important;
        height: 40px !important;
        min-width: 170px !important;
        border: 1px solid #ff3535 !important;
      }
      .button-mobile-view{
        gap: 40px !important;
      }
      .family-edit-form-align{
        margin: 15px auto;
      }
      .choose-avatar-dialog{
        margin: 0;
        height: 100%;
        display:flex;
        flex-direction: row;
      }
      
      .myfamily-card{
        min-width: 246;
      }
}