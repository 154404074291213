/*Mobile view*/
@media only screen and (max-width: 767px) {
  .help-container {
    width: 100%;
    margin: auto;
    padding-right: 20px;
    padding-left: 20px;
  }
  .box-container {
    padding: 0px !important;
    margin: 30px 0px;
  }
  .need-help-card {
    width: 80%;
  }
  .need-help-btn{
    width: 90% !important;
  }
  .location-card {
    padding: 30px 10px 30px 10px !important;
  }
  .activate-record{
    justify-content: center !important;
    flex-direction: column !important;
  }
  .need-help-box{
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    
  }
  .box-container-one{
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
}
/*Tablet view*/
@media only screen and (min-width: 768px) and (max-width: 999px) {
  .help-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%; 
  }
  .box-container {
    padding: 0px !important;
    margin: 30px 0px;
  }
  .need-help-card {
    width: 80%;
    }
  .need-help-btn{
    width: 85% !important;
  }
  .location-card {
    padding: 30px 10px 30px 10px;
  }
  .need-help-box{
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .box-container-one{
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
}
/*laptop devices */
@media only screen and (min-width: 1000px) {
  .help-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
  }
  .box-container {
    padding: 0px !important;
    margin: 30px 0px;
  }
  .need-help-card {
    width: 70%!important;
  }
  .need-help-btn{
    width: 72% !important;
  }
  .location-card {
    padding: 30px 10px 30px 10px !important;
  }
  .need-help-box{
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .box-container-one{
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
}
/* large devices */
@media only screen and (min-width: 1200px) {
  .help-container {
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 95%;
    width: 1160px;
  }
  .box-container {
    padding: 0px !important;
    margin: 30px 0px;
  }
  .need-help-card {
    width: 70% !important;
  }
  .need-help-btn{
    width: 72% !important;
  }
  .location-card {
    padding:  30px 10px 30px 10px !important;
  }
  .need-help-box{
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .box-container-one{
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
}
