.vertical-tabs {
    align-items: flex-start;
    justify-content: flex-start;
    width: 20%;
}

/* .vertical-tabs .MuiTabs-scroller {
    width: 100%;
} */
.info-tabs {
    width: 74%;
}
.vertical-tabs button.MuiTab-root:first-child {
    border-top: 1px solid #AEAEAE;
}
.row-reverse {
    flex-direction: row-reverse !important;
    align-items: center !important;
}
.vertical-tabs button.MuiTab-root {
    align-items: self-start;
    border-bottom: 1px solid #AEAEAE;
    padding: 14px 10px ;
    text-transform: capitalize;
    color: #000;
    font-weight: 500 !important;
    min-height: 50px !important;
    justify-content: space-between;
}
.vertical-tabs .MuiTabs-scroller {
    width: 90%;
}
.vertical-tabs button.MuiTab-root:hover {
    border-bottom: 1px solid #ff3535;
    color: #ff3535;
    padding-left: 15px;
}
.vertical-tabs button.MuiTab-root.Mui-selected {
    color: #fff;
    background-color: #ff3535;
    padding: 14px 10px ;
}
.vertical-tabs .MuiTabs-indicator {
    display: none;
}

.plan-details{
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
     font-size: 24px !important;
     font-weight: bold !important;
     color: #2B303E !important;
}

.plan-access{
    color:#8b8b8b !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    font-size: 20px !important;
    margin-top: 20px !important;

}

.red-text{
    color: #FF3535 !important;
    font-size: 24px !important;
    font-weight: bold !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    margin-left: 20px;
}

.valid-date{
    color: #2B303E !important;
    font-size: 19px !important;
    font-weight: bold !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    margin-left: 20px;
    margin-top: 20px !important;
}

.tool-tip-font{
    font-size:16px !important ;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }

  .heading-font{
    font-size:24px !important ;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    font-weight: bold !important;
  }
  .popup-container{
    overflow-y:auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .popup-container::-webkit-scrollbar{
    display:none; 
  }
