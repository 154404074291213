.Mt30 {
  margin-top: 30px !important;
}
.bullet-list {
  list-style-type: none;
  padding-left: 0;
  font-family: Roboto !important;
  color: #000000;
}

.bullet-list li:before {
  content: '✔'; /* Unicode character for tick */
  color: green; 
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 0px;
  margin-right: 18px;
}

.list{  
  margin-bottom: 15px;
  font-family: Roboto !important;
}

@media only screen and (max-width:767px) {
  .profile-header-container {
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;

    width: 100% !important;
  }
  .manage-password-container {
    display: flex;
    justify-content: center ;
    margin: auto;
    padding-right: 20px ;
    padding-left: 20px ;
     width: 100%;
  }
  .manage-password-margin{
    margin: 20px 0px;
  }
  .manage-password-padding-bottom{
    padding-bottom: 50px;
  }

  .profile-top-container {
    margin: 50px auto !important;
    display: flex !important;
  }

  .profile-middle-container {
    padding: 0px 8%;
  }

  .profile-box-container {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0;
    background-color: #fff;
    box-shadow: 0px 1px 12px #00000026;
  }

  .avatar-container {
    display: flex;
    justify-content: center;
  }

  .profile-title {
    text-align: center !important;
  }

  .social-icon-container {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 15px 0px -10px -10px;

  }

  .profile-socialicon {
    width: 50px;
    height: 50px;
  }

  .profile-input-gap {
    margin-top: 30px;
  }

  .profile-input-title {
    margin-top: 20px !important;
  }

  .Mt30 {
    margin-top: 25px !important;
  }
  .edit-btn-width{
  width: 50% !important;
  }
  

  /* edit profile */
  .edit-avatar-container {
    display: flex;
    justify-content: center;
  }
  .edit-icon-container{
    position: absolute;
    left: 60%;
    cursor: pointer;

  }
  .profile-dialog-content{
    padding: 9px 22px 21px 22px !important;
    overflow-y: auto;
  }

  .profile-action-btn {
    text-transform: capitalize !important;
    color: #ff3535 !important;
    height: 40px !important;
    min-width: 90px !important;
    border: 1px solid #ff3535 !important;
  }

  .profile-action-holder {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-top: 22px;
    margin-bottom: 7px;
    box-sizing: border-box
  }

  .profile-photo-dialog .MuiDialog-paper {
    height: 90%;
  }

  .profile-photo-upload-container {
    border: 2px dashed #ccc;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 0px !important;
    box-sizing: border-box;
    text-align: center;
    gap: 20px;
    cursor: pointer;
    margin-bottom: 30px;
  }
  

  .profile-left-box {
    display: flex;
    /* align-items: center;
    justify-content: center; */
    flex-direction: column !important;
    /* margin-top: 30px !important; */
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .profile-right-box {
    display: flex;
    /* align-items: center;
    justify-content: center; */
    flex-direction: column !important;
    /* margin-top: 30px !important; */
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .password-container-padding{
    padding-right: 16px;
    padding-top: 20px !important;
  }
  .securitypin-container-padding{
    padding-right: 16px;
    padding-left: 16px;
  }
  .securitylock-container-padding{
    padding-right: 16px;
    padding-top: 10px;
  }
  .btn-padding-left{
    padding-left: 30px !important;
  } 
  .subscription-plan-container{
      width: 100%;
      margin: auto;
      padding-right: 20px;
      padding-left: 20px;
  }
  .pricing-card-alignment{
    display: flex;
    flex-direction: column !important;
    gap: 50px;
  }
  .plan-tabs{
    border:1px Solid #ff3535;
    border-radius: 23px; 
    padding:2px;
    width:80%
  }
  .pricing-card-width{
    width: 220px;
  }
}

/* tablet */
@media only screen and (min-width:768px) {
  .profile-header-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .manage-password-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .manage-password-margin{
    margin: 20px 0px;
  }
  .manage-password-padding-bottom{
    padding-bottom: 50px;
  }

  .profile-top-container {
    margin: 40px auto !important;
    display: flex !important;
  }

  .profile-box-container {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0;
    background-color: #fff;
    box-shadow: 0px 1px 12px #00000026;
  }

  .profile-middle-container {
    padding: 0px;
  }

  .avatar-container {
    margin-left: 50px !important;
  }


  .social-icon-container {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 30px;
    margin-bottom: -15px;
    margin-left: -10px;
  }

  .profile-input-gap {
    margin-top: 40px;
  }

  .profile-input-title {
    margin-top: 25px !important;
  }

  .profile-input-title1 {
    margin-top: 15px !important;
  }

  /* edit profile */
  .edit-avatar-container {
    position: relative;
  }
  
    .edit-icon-container {
      position: absolute;
      right: 0;
      top: 20px;
      cursor: pointer;
  
    }
    .profile-dialog-content{
      margin-bottom: 20px;
      padding: 20px 26px 20px 26px !important;
      overflow-y: auto;
  
    }
  .profile-action-btn {
    text-transform: capitalize !important;
    color: #ff3535 !important;
    height: 40px !important;
    min-width: 170px !important;
    border: 1px solid #ff3535 !important;
  }

  .profile-action-holder {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 80px;
    margin-top: 40px;
    margin-bottom: 40px;
    box-sizing: border-box;
  }

  .profile-photo-dialog .MuiDialog-paper {
    height: 80%;
  }

  .profile-photo-upload-container {
    border: 2px dashed #ccc;
    height: 100%;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
    box-sizing: border-box;
    text-align: center;
    gap: 20px;
    margin-bottom: 30px;
    cursor: pointer;
  }
  .croper-container{
    height: 100%;
    width: auto;
      }
  .profile-left-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column !important;
    margin-top: 30px !important;
    padding-left: 32px !important;
    /* padding-right: 30px !important; */
  }
  .profile-right-box {
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    flex-direction: column !important;
    /* margin-top: 30px !important; */
    padding-left: 33px !important;
    padding-right: 30px !important;
  }
  .password-container-padding{
    padding-right: 16px;
  }
  .securitypin-container-padding{
    padding-right: 16px;
    padding-left: 16px;
  }
  .securitylock-container-padding{
    padding-right: 16px;
    padding-top: 10px;
  }
  .subscription-plan-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .pricing-card-alignment{
    display: flex;
    flex-direction: row;
    gap: 50px;
  }
  .plan-tabs{
    border:1px Solid #ff3535;
    border-radius: 23px; 
    padding:2px;
    width:50%
  }
  .pricing-card-width{
    width: 300px;
  }
}

/* laptop device */
@media only screen and (min-width:1000px) {
  .profile-header-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
  }
  .manage-password-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
  }
  .manage-password-margin{
    margin: 20px auto;
  }
  .manage-password-padding-bottom{
    padding-bottom: 160px;
  }

  .profile-top-container {
    margin: 50px auto !important;
    display: flex !important;
  }

  .profile-box-container {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0;
    background-color: #fff;
    box-shadow: 0px 1px 12px #00000026;
  }

  .social-icon-container {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 25px;
    margin-bottom: -15px;

  }

  .avatar-container {
    margin-left: 70px !important
  }

  .profile-socialicon {
    width: 50px;
    height: 50px;
  }

  /* edit profile */
  .croper-container{
    height: 100%;
    width: 100%;
      }
  .profile-photo-dialog .MuiDialog-paper {
    height: 600px;
    max-width: 650px;
  }
  .profile-dialog-content{
    padding: 20px 24px 5px 31px !important;
    margin-bottom: 0px;
    overflow-y: auto;
  }
  .password-container-padding{
    padding-right: 30px;
  }
  .securitypin-container-padding{
    padding-right: 42px;
    padding-left: 30px;
  }
  .securitylock-container-padding{
    padding-right: 42px;
    padding-top: 30px;
  }
  .subscription-plan-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
  }
  .pricing-card-alignment{
    display: flex;
    flex-direction: row;
    gap: 80px;
  }
  .plan-tabs{
    border:1px Solid #ff3535;
    border-radius: 23px; 
    padding:2px;
    width:50%
  }
  .pricing-card-width{
    width: 350px;
  }
}

/* large  device */
@media only screen and (min-width: 1200px) {
  .profile-header-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    width: 1150px !important;
  }
  .manage-password-container {
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 95%;
    width: 1160px;
  }
  .manage-password-margin{
    margin: 20px auto;
  }  
  .manage-password-padding-bottom{
    padding-bottom: 160px;
  }

  .profile-top-container {
    margin: 50px auto !important;
    display: flex !important;
  }

  .profile-box-container {
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0;
    background-color: #fff;
    box-shadow: 0px 1px 12px #00000026;
  }
   /* edit profile */
   .profile-photo-dialog .MuiDialog-paper {
    height: 600px;
    max-width: 650px;
  }
  .profile-dialog-content{
    padding: 20px 24px 5px 31px !important;
    overflow-y: auto;
  }
  .password-container-padding{
    padding-right: 30px;
  }
  .securitypin-container-padding{
    padding-right: 42px;
    padding-left: 30px;
  }
  .securitylock-container-padding{
    padding-right: 42px;
    padding-top: 30px;
  }
  .subscription-plan-container {
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 95%;
    width: 1160px;
  }
  .pricing-card-alignment{
    display: flex;
    flex-direction: row;
    gap: 80px;
  }
  .plan-tabs{
    border:1px Solid #ff3535;
    border-radius: 23px; 
    padding:2px;
    width:50%
  }
  .pricing-card-width{
    width: 350px;
  }
}