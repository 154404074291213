.header-navbar-1 {
  height: 60px;
  background: #2b303e 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  align-items: center;
  /* display: grid;
  margin: 0px !important; */
}

.header-nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.social-icon {
  padding-right: 15px;
}
.primary {
  background: #ff3535 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
}
.side-bar {
  color: white;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
}
.side-bar:hover {
  letter-spacing: 0px;
  color: #ff3535 !important;
  opacity: 1;

}
.side-menu {
  letter-spacing: 0px;
  color: #ff3535 !important;
  opacity: 1;
}

.menu-top-bar {
  color: black !important;
  height: 100px;
  box-sizing: border-box;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  border-bottom: 5px solid transparent !important;
  text-transform: capitalize;
  margin-bottom: 0px !important;
}
.menu-top-bar:hover {
  letter-spacing: 0px;
  color: #ff3535 !important;
  opacity: 1;
  border-bottom: 5px solid #ff3535 !important;
  background-color: white !important;
  
}

.active-menu {
  letter-spacing: 0px;
  color: #ff3535 !important;
  opacity: 1;
  border-bottom: 5px solid #ff3535 !important;
  /* background-color: white !important; */
}

#language-select {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ff3535 !important;
  border-radius: 5px;
  opacity: 1;
  width: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}
#userProfile {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ff3535 !important;
  border-radius: 5px;
  opacity: 1;
  width: auto;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}
.user-profile{
  margin-right: 10px !important;
  margin-left: 10px !important;
}
.user-profile-list li {
  border-bottom: 1px solid #D8D8D8;
  padding: 10px 15px;
}
.user-profile-list li:last-child {
  border-bottom: 1px solid transparent ;
}
.user-profile-list li:hover {
  color: #FF3535;
  border-bottom: 1px solid #FF3535 ;
}
ul[aria-labelledby="userProfile"] {
  padding: 0;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

#login {
  margin-top: 12px;
}
#fade-menu ul{
  padding: 0px !important;
}

.submenu-header{
  color: black !important;
  text-align: left;
  border-bottom: 1px solid #D8D8D8 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.submenu-header:hover{
  color: #ff3535 !important;
  opacity: 1;
  border-bottom: 1px solid #ff3535;
  background-color: white !important;
}

.single-menu{
  display: flex;
  flex-direction: row;
}

.single-menu-icon{
  padding-right: 10px;
  fill: "#d13a3c";
}

.single-menu-text{
  text-transform: uppercase;
  /* display: flex !important;
  align-content: center;
  flex-wrap: wrap; */
}
.alignment{
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
}
.figure {
  margin: auto;
  position: relative;
}
.menu-top-bar img.Sirv.image-hover {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  object-fit: contain;
  opacity: 0;
  transition: opacity .2s;
}
.menu-top-bar:hover img.Sirv.image-hover {
  opacity: 1;
}
.menu-top-bar:hover img.Sirv.image-main {
  opacity: 0;
}

.active-menu img.Sirv.image-hover {
  opacity: 1;
}
.active-menu img.Sirv.image-main {
  opacity: 0;
}
.react-datepicker-wrapper {
  width: 100%;
}
.profile-input {
  display: flex;
  align-items: center;
  border: 1px solid #DDDFE1;
  border-radius: 8px !important;
  height: 45px; 
  box-sizing: border-box;
  margin-bottom: 25px;
  box-shadow: none !important;
  width: 100%;
  padding: 10px 15px;
}
.profile-input-Message {
  display: flex;
  border: 1px solid #DDDFE1;
  border-radius: 8px !important;
  height: 150px; 
  box-sizing:content-box;
  margin-bottom: 25px;
  box-shadow: none !important;
  width: 100%;
  padding: 10px 15px;
}
.profile-input:focus-visible {
  outline: none;
}
.profile-input-label {
  margin-bottom: 10px !important;
}

.action-holder {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 80px;
  margin-top: 40px;
  margin-bottom: 40px;
  box-sizing: border-box;
}
/* remove dialog popup */
.action-holder-1 {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
  box-sizing: border-box;
}
/* add/edit popup */
.action-holder-2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 25px;
  gap: 80px;
  margin-top: 40px;
  margin-bottom: 40px;
  box-sizing: border-box;
}
.action-btn {
  text-transform: capitalize !important;
  color: #ff3535 !important;
  height: 40px !important;
  min-width: 170px !important;
  border: 1px solid #ff3535 !important;
}
.action-btn-1 {
  text-transform: capitalize !important;
  color: #fff !important;
  background-color: #ff3535 !important;
  height: 40px !important;
  min-width: 170px !important;
  border: 1px solid #ff3535 !important;
}
.action-btn-yes, .action-btn-no {
  text-transform: capitalize !important;
  color: #ff3535 !important;
  height: 40px !important;
  min-width: 100px !important;
  border: 1px solid #ff3535 !important;
}
.save-btn {
  text-transform: capitalize !important;
  color: #ff3535 !important;
  border: 1px solid #ff3535 !important;
}
.profile-save-btn:hover {
  background-color: #FF3535 !important;
  color: #fff !important;
}
.profile-outline-btn:hover {
  background-color: #fff !important;
}
.dialog-box .MuiDialog-paper {
  width: 70%;
  padding: 20px 0;
}
.dialog-box-custom .MuiDialog-paper {
  background-color: #000000BA;
        opacity: 1;
}
.photo-dialog .MuiDialog-paper {
  height: 60%;
  /* max-width: 45%; */
}
.dialog-box .MuiDialogTitle-root {
  display: flex;
  justify-content: space-between;
  padding: 0 42px;
}

.dialog-box .MuiDialogContent-root {
  padding: 30px 42px 0 42px;
}
.error-message {
  float: right;
  font-size: 12px !important;
  color: #FF3535;
  display: flex;
}
.error-border {
  border: 1px dashed #FF3535 !important;
  box-shadow: 0px 2px 10px #00000029 !important;
}
.err-input {
  border: 1px solid #FF3535 !important;
  box-shadow: 0px 2px 10px #00000029 !important;
}
.left-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column !important;
  margin-top: 30px !important;
  padding-left: 32px !important;
  padding-right: 30px !important;
}
.avatar-upload-icon {
  width: 215px;
  height: 215px;
  border-radius: 100%;
  object-fit: fill;
  border: 1px solid #DDDFE1;
}
.avatar-upload-icon1 {
  width: 150px !important;
  height: 160px !important;
  border-radius: 100%;
  object-fit: fill;
  border: 1px solid #DDDFE1;
}
.input-container-btm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.avatar-photo-icon {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  object-fit: fill;
  margin-right: 23px;
  margin-bottom: 25px;
  display: inline-block;
}
/* .avatar-photo-icon:hover {
  box-shadow: 0px 2px 10px #00000029;
  cursor: pointer;
} */
.photo-upload-container {
  border: 2px dashed #ccc;
  height: 100%;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
  text-align: center;
  gap: 20px;
  cursor: pointer;
}
.react-date-picker__wrapper {
  border: none !important; 
}
.react-date-picker__calendar {
  inset: 100% 0px auto auto !important;
}
.react-date-picker__inputGroup__input:focus-visible {
  background: none !important;
  outline: none !important;
}
/* .react-date-picker__button svg {
  background: url('../../../../assets/images/family-chat.png') no-repeat;
} */
.react-calendar__tile--now {
  background: #ff353536 !important;
}
.react-calendar__month-view__days__day--weekend {
  color: #ff3535 !important;
}
.react-calendar__tile--active {
  background: #ff3535 !important;
  color: white !important;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon, .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #bbb  !important;
}
.react-date-picker__inputGroup__input:invalid {
  background-color: #E0E0 !important;
}
.content-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 25px;
  gap: 19px;
  text-align: center;
}
.small-dialog .MuiDialog-paper {
  max-width: 390px;
}
.small-dialog1 .MuiDialog-paper {
  max-width: 530px;
}
.small-dialog1 .MuiDialog-paper {
  max-width: 530px;
}
.reset-dialog .MuiDialog-paper {
  max-width: 565px;
}
.MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}
.MuiSelect-select.MuiSelect-outlined {
  border: 1px solid #DDDFE1;
}
.txt-area .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}
.language-container {
  display: flex;
  gap: 8px;
  min-width: 88px;
  max-height: 39px;
}
.header-last-containar{
  display: flex !important;
 justify-content: end;

 }
/* mobile devices */
@media only screen and (max-width: 767px) {
  
  .header-navbar-container {
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100% !important;
  }
  .header-nav-bar {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }.social-icon {
    padding-right: 6px;
  }
  
  .social-icon-logo {
    width: 26px;
    height: 29px;
  }
  
  .header-last-containar {
    display: flex !important;
    gap: 6px
  }
  
  .language-container {
    display: flex;
    gap: 7px;
    margin-left: 4px;
    margin-right: -4px;
    min-width: 44px !important;
    height: 24px;
    align-items: center
  }
  .language-dropdown{
    min-height: 25px !important;
    padding-bottom: 0px !important;
  }
  .MuiSelect-select.MuiInputBase-input.MuiSelect-select {
    height: 20px !important;
  }
  
  .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
    padding: 0px 16px 1px 1px !important;
  }
  
  .MuiSvgIcon-root.MuiSelect-icon {
    right: 0px !important
  }
  
  .user-profile {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  
  .user-profile-icon {
  
    height: 17px;
    width: 17px;
    border-radius: 50%;
  }
  
  .MuiBadge-badge {
    height: 15px !important;
    min-width: 15px !important;
    padding: 0px 3px !important;
    margin-top: 1px !important;
    margin-right: 3px !important;
}
  .notificaion-img {
    height: 26px;
    width: 29px;
    cursor: pointer;
  }
   #mrflogo {
    margin-left: 10px;
    width: 250px;
  }
  .MuiPaper-root.MuiDrawer-paper {
    width: 320px !important;
    background-color: #2B303E;

    overflow-y: auto;
}
  .list-menu{
    font-size: 100px!;
  }
  /* ActiveAlert */
    .header-panel {
      min-height: 100px;
      background: transparent linear-gradient(180deg, #CFD5C7 0%, #F7EBDA 65%, #FEF2E1 100%) 0% 0% no-repeat padding-box;
      opacity: 1;
    }
   .help-alert{
    /* margin-top: 10px; */
     width: 100%;
   }
   .help-alert1{
    display: none !important
     }
     .go-back-title{
      margin-top: 0px !important;
    cursor: pointer;
    margin-left: 2px !important;
     }
     .activate-alert-container{
      margin: 19px 0px 15px !important;
     }

     /* notification */
     .noti-main-container{
      /* height:90%; */
    overflow-y: auto;
    margin:auto ;
     }
     .noti-title-container{
      margin-bottom: 10px;
     }
     .laptop-view{
      display: none;
     }
     .drawer-container .MuiPaper-root.MuiDrawer-paper {
      height: 100%;
      margin-top: 60px;
  
      width: 320px !important;
      background-color:white;
      overflow-y: auto;
    }
    .action-holder {
      width: 100%;
      display: flex;
      justify-content: center;
      gap:20px;
      margin-top: 10px;
      margin-bottom: 35px;
      box-sizing: border-box;
    }
    /* remove dialog popup */
    .action-holder-1 { 
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0px 20px;
      gap:20px;
      margin-top: 10px;
      margin-bottom: 35px;
      box-sizing: border-box;
    }
    /* add/edit popup */
    .action-holder-2 {
      gap: 0px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0px 20px;
      margin-top: 10px;
      margin-bottom: 35px;
      box-sizing: border-box;
    }
    .action-btn {
      text-transform: capitalize !important;
      color: #ff3535 !important;
      height: 40px !important;
      min-width: 100px !important;
      border: 1px solid #ff3535 !important;
    }
}
/* tablet devices */
@media only screen and (min-width: 768px) {

  .header-navbar-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .header-nav-bar {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
    .social-icon-logo {
      width: 36px;
      height: 36px;
    }
  
    .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input{
      padding-top: 6px !important;
      padding-bottom: 6px !important;
    }
  
    .user-profile-icon {
      height: 25px;
      width: 25px;
      border-radius: 50%;
    }
  .MuiBadge-badge {
    margin-top: 1px !important;
    margin-right: 3px !important;
}
 .notificaion-img {
    height: 41px;
    width: 40px;
    cursor: pointer;
 }
  #mrflogo {
    width: 296px;
    height: 63px;
  }
  .MuiPaper-root.MuiDrawer-paper {
    width: 320px !important;
    background-color: #2B303E;
    overflow-y: auto;
  }
 
  .img-size{
    margin-left: -15px !important ;
  }
  .menu-icon-size
 {
  width: 35px ;
  height: 35px;
}
/* ActiveAlert */
.header-panel{
  height: 200px;
   background: transparent linear-gradient(180deg, #CFD5C7 0%, #F7EBDA 65%, #FEF2E1 100%) 0% 0% no-repeat padding-box;
   opacity: 1;
 }
 .help-alert{
   width: 100%;
 }
 .help-alert-mobile{
  display: none
   }
   .go-back-title{
    margin-top: 25px !important;
  cursor: pointer;
  margin-left: 2px !important;
   }
     /* notification */
     .noti-main-container{
      height:-webkit-fill-available;
      overflow: auto;
      margin: 0px auto 40px !important;     
    }
    .noti-title-container{
      margin-bottom: 20px;
     }
    .mobile-view{
      display: block;
     }
     .drawer-container .MuiPaper-root.MuiDrawer-paper {
      height: 100%;
      margin-top: 61px;
  
      min-width: 350px !important;
      background-color:white;
      overflow-y: auto;
    }
}

/* laptop devices */
@media only screen and (min-width: 1000px) {
  .header-navbar-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
  }
  .header-nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .user-profile-icon {
    height: 27px;
    width: 27px;
    border-radius: 50%;
  }
  #mrflogo {
    margin: 10px 0px 5px 0px !important;
    width: 389px;
    height: 79px;
  }
  .MuiPaper-root.MuiDrawer-paper {
    min-width: 320px !important;
    background-color: #2B303E;

    overflow-y: auto;
  }
  .img-size{
    margin-left: -20px !important ;
  }
  .menu-icon-size
 {
  width: 35px ;
  height: 35px;
}
/* ActiveAlert */
.header-panel{
  height: 200px;
   background: transparent linear-gradient(180deg, #CFD5C7 0%, #F7EBDA 65%, #FEF2E1 100%) 0% 0% no-repeat padding-box;
   opacity: 1;
 }
 .help-alert{
   width: 80%;
 }

  /* notification */
  .noti-main-container{
    height: -webkit-fill-available;
    overflow: auto;
    margin: 0px auto 40px !important;
   }
   .drawer-container .MuiPaper-root.MuiDrawer-paper {
    height: 100%;
    margin-top: 61px;

    min-width: 366px !important;
    background-color:white;
    overflow-y: hidden;
  }
 
}
/* largedevices */
@media only screen and (min-width: 1200px) {

  .header-navbar-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    width: 1150px !important;
  }
  .header-nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .user-profile-icon {
    height: 27px;
    width: 27px;
    border-radius: 50%;
  }
  #mrflogo {
    margin: 10px 0px 5px 9px !important;
    width: 389px;
    height: 79px;
  }
  .img-size{
    margin-left: -20px !important ;
  }
  .menu-icon-size
 {
  width: 35px ;
  height: 35px;
}
/* ActiveAlert */
.header-panel{
  height: 200px;
   background: transparent linear-gradient(180deg, #CFD5C7 0%, #F7EBDA 65%, #FEF2E1 100%) 0% 0% no-repeat padding-box;
   opacity: 1;
 }
 .help-alert{
  width: 338px;
  height: 140px;
 }
 /* notification */

}