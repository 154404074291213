@media only screen and (max-width: 767px) {
  .location-container {
    width: 100%;
    margin: auto;
    padding-right: 20px;
    padding-left: 20px;
  }
  .location-container-2 {
    width: 100%;
    margin: auto;
    padding-right: 0px;
    padding-left: 0px;
  }
  .location-box .MuiDialog-paper {
    width: 90% !important;
  }
  .box-container {
    padding: 0px !important;
    margin: 40px 0px;
  }
}
.location-card {
  padding: 30px 10px 20px 10px !important;
}

.location-meeting-content{
  width: 75%;
}
.location-box .MuiDialogTitle-root{
  padding: 0px 35px !important;
}
.location-box .MuiDialogContent-root {
  padding: 30px 30px 0 30px !important;
}
/* .cancel-save-btn{
  margin:10px 20px 0px 20px  !important;
  
} */

.emergency-alert-location-header{
  display:flex;
   flex-direction:row;
    padding-top:10px
}
/* tablet devices */
@media only screen and (min-width: 768px) and (max-width: 999px) {
  .location-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .location-container-2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .box-container {
    padding: 0px !important;
    margin: 50px 0px;
  }
  .location-card {
    padding: 30px 10px 20px 10px;
  }
  /* .location-card-content{
    padding-left: 8px;
    padding-right: 8px;
  } */
  /* .meeting-location{
    padding-left: 10px ;
    padding-right: 10px;
  } */
  .location-meeting-content{
    width: 90%;
  }
  .location-box .MuiDialogTitle-root{
    padding: 0px 35px !important;
  }
  .location-box .MuiDialogContent-root {
    padding: 30px 30px 0 30px !important;
}
/* .cancel-save-btn{
  margin: 0px 30px !important;
  justify-content: space-between !important;
} */
}
/*laptop devices */
@media only screen and (min-width: 1000px) {
  .location-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
  }
  .location-container-2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
  }
  .box-container {
    padding: 0px !important;
    margin: 50px 0px;
  }
  .location-card {
    padding: 30px 10px 20px 10px !important;
  }
  .location-card-content{
    padding-left: 8px;
    padding-right: 8px;
  }
  .card-location-meeting{
    max-width: 450;
  }
  .meeting-location{
    padding-left: 10px;
    padding-right: 10px;
  }
  .location-meeting-content{
    width: 75%;
  }
  .location-box .MuiDialogTitle-root{
    padding: 0px 35px !important;
  }
  .location-box .MuiDialogContent-root {
    padding: 30px 30px 0 30px !important;
}
/* .cancel-save-btn{
  margin: 0px 30px !important;
  justify-content: space-between !important;
} */
}
/* large devices */
@media only screen and (min-width: 1200px) {
  .location-container {
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 95%;
    width: 1160px;
  }
  .location-container-2 {
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 95%;
    width: 1160px;
  }
  .box-container {
    padding: 0px !important;
    margin: 50px 0px;
  }
  .location-card {
    padding:  30px 10px 20px 10px !important;
  }
  
  .location-card-content{
    padding-left: 8px;
    padding-right: 8px;
  }
  .card-location-meeting{
max-width: 450;
  }
  .meeting-location{
    padding-left: 10px;
    padding-right: 10px;
  }
  .location-meeting-content{
    width: 75%;
  }
  .location-box .MuiDialogTitle-root{
    padding: 0px 35px !important;
  }
  .location-box .MuiDialogContent-root {
    padding: 30px 30px 0 30px !important;
}
/* .cancel-save-btn{
  margin: 0px 30px !important;
  justify-content: space-between !important;
} */
}
