#login-layout{
    background: url('../../../../assets/images/my-family.png') no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;
    /* overflow: hidden; */
    /* position: fixed; */
}
#account-layout{
  background: url('../../../../assets/images/my-family.png') no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-size: cover;
  padding-top: 50px;
  padding-bottom: 50px;
  box-sizing: border-box;
  /* overflow: hidden; */
  /* position: fixed; */
}
#login-container {
    width: 428px;
    background: white;
    border-radius: 10px;
    opacity: 1;
    overflow-y: auto;
    height: 95%;
}

.loginScreen .MuiDialog-paper {
    width: 428px;
    /* height: 85%; */
    overflow-y: scroll;
    border-radius: 10px;
  
  }
  #login-container::-webkit-scrollbar{
    display: none;
  } 
  .loginScreen #login-title {
    text-align: center;
    padding-top: 50px;
  }
  .loginScreen #login-content {
    background: transparent linear-gradient(180deg, #D93232 0%, #6C0909 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 50px 25px;
  }
  .input-holder {
    position: relative;
  }
  .input-icon-holder {
    position: absolute;
    top: 30%;
    left: 30px;
  }
  .input-icon-holder-external-icon {
    position: absolute;
    top: 20%;
    left: 30px;
  }
  .input-eye-icon {
    position: absolute !important;
    top: 12%;
    right: 30px;
  }
  .input-eye-icon svg path {
    fill: #AEAEAE;
  }
  .pwd-icon {
    position: absolute !important;
    top: 10%;
    right: 30px;
  }
  .pwd-icon1 {
    position: absolute !important;
    top: 0;
    right: 30px;
  }
  
  .login-input {
    display: flex;
    align-items: center;
    box-shadow: inset 0px 2px 8px #00000080 !important;
    border-radius: 30px !important;
    height: 60px; 
    padding: 15px 25px 15px 60px; 
    box-sizing: border-box;
    margin-bottom: 20px;
    width: 100%;
    border: none;
  }
  .login-input:focus-visible {
    outline: none;
  }
  .login-input::placeholder {
    color: #AEAEAE;
  }
  
  .loginScreen .MuiDialogContent-root {
    padding: 0;
    overflow-y: unset !important;
  }
  .forgot-pwd {
    display: flex;
    justify-content: end;
    color: #fff;
    text-decoration: underline;
  }
  .signin-btn {
    width: 100% !important;
    border-radius: 30px !important;
    height: 60px !important;
    background: #000000 0% 0% no-repeat padding-box !important;
    text-transform: capitalize !important;
    margin-top: 20px !important;
    box-shadow: 0px 3px 6px #00000029 !important;
  }
  .divider1 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    border-color: #fff !important;
  }
  .divider2 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    border-color: #fff !important;
    width: 43%;
  }
  .or-style {
    padding: 15px;
    color: #FFFFFFD9;
  }
  .or-divider-holder {
    display: flex;
    align-items: center;
  }
  .create-acc-btn {
    width: 100% !important;
    border-radius: 10px !important;
    height: 45px !important;
    color: #ff3535 !important;
    background: #fff !important;
    text-transform: capitalize !important;
    margin-top: 20px !important;
  }
  .box-spacing {
    margin-bottom: 15px;
    height: 50px !important;
  }
  .error-msg-holder {
    top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(255, 232, 170);
    border-radius: 20px;
    height: auto;
    width: 80%;
    margin: auto;
    padding: 8px 20px;
    box-sizing: border-box;
    gap: 10px;
    position: relative;
  }

.image-hover{
  cursor: pointer;
}
.close-icon-lock-popup{
height: 20px;
width: 20px;
}
.dialog-box-lock .MuiDialogContent-root {
padding: 30px 30px 0 30px !important;
}
.lock-icon-lock-popup{
 margin-right: 10px;
  margin-top: 30px;

}
.security-input-lock .MuiOutlinedInput-notchedOutline, .security-input-lock .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
border-color: transparent;
}
.security-input-lock {
border-radius: 10px;
}
.security-input-lock .Mui-focused .MuiOutlinedInput-notchedOutline{
border: 1px solid #C7CBCF !important;
border-radius: 10px;
box-shadow: 0px 2px 8px #00000029;
}
.security-input-lock input {
text-align: center;
border-radius: 10px ;
border: 1px solid #C7CBCF !important;
}
.content-dialog-lock {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 25px;
  gap: 19px;
  text-align: center;
}
.dialog-box-pin .MuiDialogContent-root {
  padding: 30px 30px 0 30px !important;
}
.forgot-security-pin{
  cursor: pointer;
  color: red;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 35px;
  text-decoration: underline;
}
.small-dialog-lock .MuiDialog-paper {
  max-width: 390px;
}

/* mobile */
@media only screen and (max-width:767px){
  #login-layout{
    background: url('../../../../assets/images/my-family.png') no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* height: 100vh; */
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;
    /* overflow: hidden; */
    /* position: fixed; */
}

#login-container {
    width: 90%;
    background: white;
    border-radius: 10px;
    opacity: 1;
    overflow-y: auto;
    height: 95%;
}
.loginScreen #login-content {
  background: transparent linear-gradient(180deg, #D93232 0%, #6C0909 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 50px 15px;
}
.login-title-img{
  width: 80%;
}
.create-acc-btn {
  width: 100% !important;
  border-radius: 10px !important;
  height: 45px !important;
  color: #ff3535 !important;
  background: #fff !important;
  text-transform: capitalize !important;
  margin-top: 20px !important;
  margin-bottom: -17px !important;
}
.forgot-pwd {
  display: flex;
  justify-content: end;
  color: #fff;
  margin-right: 18px;
  text-decoration: underline;
}
  /* forgot */
  .forgot-container{
    width:80%
  }
  .forgot-container-top{
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
  }
  .forgot-card-container{
  padding: 20px 20px !important;
}
.close-icon{
  width: 22px;
}
/* security lock */
.close-icon-lock-popup{
height: 20px;
width: 20px;
}
.dialog-box-lock .MuiDialogContent-root {
padding: 30px 15px 0 15px !important;
}
.lock-icon-lock-popup{
margin-right: 10px;
 margin-top: 0px;

}
.security-input-lock input {
padding: 10px !important;
text-align: center;
border-radius: 10px !important;
border: 1px solid #C7CBCF !important;
}
.content-dialog-lock {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 3px;
  gap: 19px;
  text-align: center;
}
.small-dialog-lock .MuiDialog-paper {
  max-width: 266px;
}
/* pin  popup */
.dialog-box-pin .MuiDialogContent-root {
  padding: 30px 30px 0 30px !important;
}
.forgot-security-pin{
  cursor: pointer;
  color: red;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 25px;
  text-decoration: underline;
}
.setpassword-container{
  min-width: 35%;
}

}
/* tablet */
@media only screen and (min-width:768px){
  .forgot-pwd {
    display: flex;
    justify-content: end;
    color: #fff;
    margin-right: 18px;
    text-decoration: underline;
  }
  /* forgot */
  .forgot-container{
    width:70%
  }
  .forgot-container-top{
    display: flex;
    justify-content: space-between;
    padding: 12px 30px;
  }
  .forgot-card-container{
    padding: 15.5px 30px 32px !important;
  }
  .close-icon{

    width: 26px;
  
  }
  /* security-lock */
  .lock-icon-lock-popup{
    margin-right: 10px;
     margin-top: 0px;
    
    }
  .content-dialog-lock {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 3px;
    gap: 19px;
    text-align: center;
  }
  .setpassword-container{
    min-width: 60%;
  }
    
}
/* lapatop */
@media only screen and (min-width:1000px){
   /* forgot */
  .forgot-container{
    width:600px
  }
  .setpassword-container{
    min-width: 50%;
  }

}
/* large device */
@media only screen and (min-width:1200px){
  
  .setpassword-container{
    min-width: 35%;
    min-height: 60%;
  }
  .setpassword-btn-margin{
    margin-top: 50px;
  }
  
 
}