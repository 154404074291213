/* mobile devices */
@media only screen and (max-width: 639px) {
    .disaster-card-container{
        display: flex;
        justify-content: center ;
        margin: auto;
        padding-right: 20px ;
        padding-left: 20px ;
    }
    .btn-width{
        text-transform: capitalize !important;
        color: #ff3535 !important;
        height: 40px !important;
        border: 1px solid #ff3535 !important;
        max-width: 100px !important;
      }
      .message-dropdown .MuiSelect-select.MuiInputBase-input{
        height: 45px !important;
        padding: 10px 15px !important;
        box-sizing: border-box;
      }
      .photo-upload-container-gap{
        gap: 5px !important;
        padding: 20px 30px;
      }
      .disaster-container-margin{
        margin: 20px 0px;
      }
      .photo-upload-container-height{
        height: unset;
      }
}

/* tablet devices */
@media only screen and (min-width: 768px) and (max-width: 999px) {
    .disaster-card-container{
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }
    .btn-width{
        text-transform: capitalize !important;
        color: #ff3535 !important;
        height: 40px !important;
        min-width: 170px !important;
        border: 1px solid #ff3535 !important;
      }
      .disaster-container-margin{
        margin: 20px 0px;
      }
      .photo-upload-container-height{
        height: 300px;
      }
}

/*laptop devices */
@media only screen and (min-width: 1000px) {
    .disaster-card-container{
        margin-left: auto;
        margin-right: auto;
        max-width: 95%;
        padding-left: 0;
        padding-right: 0;
        width: 95%;
    }
    .btn-width{
        text-transform: capitalize !important;
        color: #ff3535 !important;
        height: 40px !important;
        min-width: 170px !important;
        border: 1px solid #ff3535 !important;
      }
      .disaster-container-margin{
        margin: 20px auto;
      }
      .photo-upload-container-height{
        height: 300px;
      }
}


/* large devices */
@media only screen and (min-width: 1200px) {
    .disaster-card-container{
        margin-left: auto;
        margin-right: auto;
        padding-right: 15px;
        padding-left: 15px;
        max-width: 95%;
        width: 1160px;
    }
    .btn-width{
        text-transform: capitalize !important;
        color: #ff3535 !important;
        height: 40px !important;
        min-width: 170px !important;
        border: 1px solid #ff3535 !important;
      }
      .disaster-container-margin{
        margin: 15px auto;
      }
      .photo-upload-container-height{
        height: 300px;
      }
}