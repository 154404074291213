
.general-card{
  /* width: 338px;  */
  /* height: 300px; */
  width: 100%;

  border: 1px solid #EAECF0 !important;
  border-radius: 10px;
  /* margin-bottom: 50px; */
}

.general-card:hover{ 
  border: 1px solid #ff3535 !important;
  cursor: pointer;
  box-shadow: 0px 2px 8px #00000033 !important;
 }
 
 .general-card-image{
  /* width: 338px;
  height: 230px; */
  width: 100%;
  position: relative;
 }

 .sub-card-image{
  width: 226px;
  /* height: 159px; */
 }
 .general-card-small-icon {
  position: absolute;
  top: 10px;
  right: 10px;
 }

 .general-card-action{
  display: flex;
  flex-direction: row;
  height:70px;
  padding:0px !important;
  justify-content:center;
  align-items:center
 }

.card-box {
  box-shadow: none !important;
  border: 1px solid #D8D8D8;
  border-radius: 10px !important;
  padding: 30px 10px 13px 10px;
  min-height: 315px;
  width: 250px;
  text-align: center;
  box-sizing: border-box;
}
.card-box:hover {
  border: 1px solid #ff3535;
  cursor: pointer;
  box-shadow: 0px 2px 8px #00000033 !important;
}

.card-box1 {
  box-shadow: none !important;
  border: 1px solid #D8D8D8;
  border-radius: 10px !important;
  padding: 30px 10px 13px 10px;
  max-height: 290px;
  width: 240px;
  text-align: center;
  box-sizing: border-box;
}
.card-box1:hover {
  border: 1px solid #ff3535;
  cursor: pointer;
  box-shadow: 0px 2px 8px #00000033 !important;
  max-height: 290px;
  width: 250px;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #ff3535;
  cursor: pointer;
  box-shadow: 0px 2px 8px #00000033 !important;
}
.cardSocial-box {
  box-shadow: none !important;
  border: 1px solid #D8D8D8;
  border-radius: 10px !important;
  padding: 30px 10px 13px 10px;
  height: 170px;
  width: 154px;
  text-align: center;
  box-sizing: border-box;
}
.cardSocial-box:hover {
  border: 1px solid #ff3535;
  cursor: pointer;
  box-shadow: 0px 2px 8px #00000033 !important;
}

.card-content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}
.admin-box {
  min-width: 109px;
  height: 31px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F1FFF7;
  color: #3BC273;
  font-size: 12px;
}
.status-box {
  min-width: 109px;
  height: 31px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #AB3E7E;
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.08px;
}
.action-button-resent {
  background: url('../../../../assets/icons/resend-invite.svg') no-repeat;
  width: 35px;
  height: 35px;
  background-size: contain;
  cursor: pointer;
}
.action-button-resent:hover {
  background: url('../../../../assets/icons/resend-invite-filled.svg') no-repeat;
  width: 35px;
  height: 35px;
  background-size: contain;
}
.admin-inactive-box {
  width: 109px;
  height: 31px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FEFAFA;
  color: #FF3535;
  font-size: 12px;
}
.admin-inprogress-box {
  width: 109px;
  height: 31px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FEFAFA;
  color: #ff9a35;
  font-size: 12px;
}
.action-button-copy {
  background: url('../../../../assets/icons/copy.svg') no-repeat;
  width: 35px;
  height: 35px;
  background-size: contain;
  cursor: pointer;
}
.action-button-copy:hover {
  background: url('../../../../assets/icons/copy.svg') no-repeat;
  width: 35px;
  height: 35px;
  background-size: contain;
  cursor: pointer;
}
.action-button-pdf {
  background: url('../../../../assets/images/pdf.svg') no-repeat;
  width: 35px;
  height: 35px;
  background-size: contain;
  cursor: pointer;
}
.action-button-pdf:hover {
  background: url('../../../../assets/images/pdf-filled.svg') no-repeat;
  width: 35px;
  height: 35px;
  background-size: contain;
}
.action-button-dlt {
  background: url('../../../../assets/images/delete.png') no-repeat;
  width: 35px;
  height: 35px;
  background-size: contain;
  cursor: pointer;
}
.action-button-dlt:hover {
  background: url('../../../../assets/images/delete-filled.png') no-repeat;
  width: 35px;
  height: 35px;
  background-size: contain;
}
.inactive-button-dlt {
  background: url('../../../../assets/images/inactive-delete.svg') no-repeat;
  width: 35px;
  height: 35px;
  background-size: contain;
}
.inactive-button-edit {
  background: url('../../../../assets/images/inactive-edit.svg') no-repeat;
  width: 35px;
  height: 35px;
  background-size: contain;
}

.action-button-edit {
  background: url('../../../../assets/images/edit.png') no-repeat;
  width: 35px;
  height: 35px;
  background-size: contain;
  cursor: pointer;
}
.action-button-edit:hover {
  background: url('../../../../assets/images/edit-filled.png') no-repeat;
  width: 35px;
  height: 35px;
  background-size: contain;
}
.action-button-user {
  background: url('../../../../assets/icons/authorisedUser-icon.svg') no-repeat;
  width: 35px;
  height: 35px;
  background-size: contain;
}
.action-button-user:hover {
  background: url('../../../../assets/icons/authorisedUser-icon-filled.svg') no-repeat;
  width: 35px;
  height: 35px;
  background-size: contain;
}
.add-icon {
  background: url('../../../../assets/images/add.png') no-repeat;
  width: 35px;
  height: 35px;
  background-size: contain;
}
.add-card-box:hover .add-icon {
  background: url('../../../../assets/images/add-white.png') no-repeat;
  width: 35px;
  height: 35px;
  background-size: contain;
}
.add-card-box {
  box-shadow: none !important;
  border: 1px solid #D8D8D8;
  border-radius: 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 315px;
  box-sizing: border-box;
}
.add-card-box:hover {
  border: 1px solid #ff3535;
  background: #ff3535 ;
  cursor: pointer;
  color: #fff;
  box-shadow: 0px 2px 8px #00000033 !important;
}
.add-card-box1 {
  box-shadow: none !important;
  border: 1px solid #D8D8D8;
  border-radius: 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-height: 290px !important;
  width: 240px;
  box-sizing: border-box;
}
.add-card-box1:hover {
  border: 1px solid #ff3535;
  background: #ff3535 ;
  cursor: pointer;
  color: #fff;
  box-shadow: 0px 2px 8px #00000033 !important;
}
.add-card-box1:hover .add-icon {
  background: url('../../../../assets/images/add-white.png') no-repeat;
  width: 35px;
  height: 35px;
  background-size: contain;
}
.add-socialicon-box {
  box-shadow: none !important;
  border: 1px solid #D8D8D8;
  border-radius: 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 170px !important;
  width: 150px;
  box-sizing: border-box;
}
.add-socialicon-box:hover {
  border: 1px solid #ff3535;
  background: #ff3535 ;
  cursor: pointer;
  color: #fff;
  box-shadow: 0px 2px 8px #00000033 !important;
}
.add-socialicon-box:hover .add-icon {
  background: url('../../../../assets/images/add-white.png') no-repeat;
  width: 35px;
  height: 35px;
  background-size: contain;
}
.add-socialicon-box:hover {
  max-height: 290px;
  box-sizing: border-box;
}

.load {
  margin-top: 5em;
  width: 99%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1000;

  &--fullscreen{
    width: 100vh;
    height: 100vh;
  }

  & + div {
    height: 100vh;
    overflow: hidden;
  }

  &.loaded {
    animation: ease loaded 0.5s;
  }
}

.load__icon {
  animation: linear load 2s infinite;
  margin: auto;
  display: flex;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.load__icon-wrap {
  margin: auto;
}

@keyframes load {
  from {
    transform: rotate(0deg) scale(2);
  }
  to {
    transform: rotate(360deg) scale(2);
  }
}

@keyframes loaded {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.name-wrapper {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.relation-select .MuiSelect-select {
  height: 45px !important;
  padding: 10px 15px !important;
  box-sizing: border-box;
}
.relation-select .MuiSelect-select.MuiInputBase-input {
  height: 45px !important;
  padding: 10px 15px !important;
  box-sizing: border-box;
}
.state-input .MuiSelect-select.MuiSelect-outlined{
 border: 1px solid transparent;
 padding-left: 0;
}
.MuiMenu-list {
  max-height: 200px;
}
.MuiMenu-list li {
  font-size: 14px;
}
/* mobile */
@media only screen and (max-width:767px){
  .card-main-container{
    padding: 34px 0px !important;
    }
    .general-card-image-main{
      width: 100%;
     }
     .card-box {
      box-shadow: none !important;
      border: 1px solid #D8D8D8;
      border-radius: 10px !important;
      padding: 12px 10px 13px 10px;
      min-height: 280px;
      width: 250px;
      text-align: center;
      box-sizing: border-box;
    }
}
/* tablet */
@media only screen and (min-width:768px){
  .card-main-container{
    padding: 40px 0px !important;
    }
    .general-card-image-main{
      height: 230px !important;
      width: 100%;
     }
}
/* lapatop */
@media only screen and (min-width:1000px){
  .card-main-container{
    padding: 40px 0px !important;
    }
    .general-card-image-main{
      height: 182px !important;
      width: 100%;
     }
}
/* large device */
@media only screen and (min-width:1200px){
  .card-main-container{
    padding: 40px 0px !important ;
    }
    .general-card-image-main{
      height: 182px !important;
      width: 100%;
     }
}