
/* mobile devices */
@media only screen and (max-width: 639px) {
  .footer-container-navbar {
    display: flex;
    justify-content: center !important;
    margin: auto;
    padding-right: 20px !important;
    padding-left: 20px !important;
    /* width: 100%; */
  }
  .footer-bottom-endcontainer {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column !important;
  }
  .trustedsite-img {
    margin: 0px auto;
    width: 75%;
    /* height: 58px; */
  }

.mcafee-img {
    margin: 20px auto 0px auto;
    width: 60%;
    /* height: 70px; */
  }
  .mobile-view{
    display: block !important;
  }
  .laptop-view{
    display: none !important;
  }
}

/* tablet devices */
@media only screen and (min-width: 768px) and (max-width: 999px) {
  .footer-container-navbar {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .footer-bottom-endcontainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.trustedsite-img {
  margin-right: 7%;
  width: 350px;
  height: 58px;
}

.mcafee-img {
  width: 194px;
  height: 70px;
}
.mobile-view{
  display: none !important;
}
.laptop-view{
  display: block !important;
}
.emergency-tile-image{
  /* margin-top:10px; */
  display:flex;
  justify-content:center;
}
.emergency-tile{
  display: flex;
  flex-direction: column;
}
}

/*laptop devices */
@media only screen and (min-width: 1000px) {
  .footer-container-navbar {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
  }
  .footer-bottom-endcontainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
    .trustedsite-img {
        margin-right: 17%;
        width: 350px;
        height: 58px;
    }

    .mcafee-img {
        width: 194px;
        height: 70px;
    }
    .mobile-view{
      display: none !important;
    }
    .laptop-view{
      display: block !important;
    }
    .emergency-tile-image{
      margin-top:10px;
      display:flex;
      justify-content:flex-start;
    }

}

/* large devices */
@media only screen and (min-width: 1200px) {
  .footer-container-navbar {
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 95%;
    width: 1160px;
  }
  .footer-bottom-endcontainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
    .trustedsite-img {
        margin-right: 17%;
        width: 350px;
        height: 58px;
    }

    .mcafee-img {
        width: 194px;
        height: 70px;
    }
    .mobile-view{
      display: none !important;
    }
    .laptop-view{
      display: block !important;
    }
    .emergency-tile-image{
      margin-top:10px;
      display:flex;
      justify-content:flex-start;
    }

}

.footer__actionbar {
  margin: 0px !important  ;
  display: grid;
  align-items: center;
  min-height: 196px;
  background: #fdf6e3 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 1px 12px #00000033;
  opacity: 1;
  position: relative;
  z-index: -1;
}
.footer-container {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0;
  justify-content: space-around;
  align-items: center;
  gap: 30px;
}
.footer__topbar {
  margin: 0px !important  ;
  min-height: 150px;
  background: #e9e9e9 0% 0% no-repeat padding-box;
  display: grid;
  align-items: center;
  align-content: center;
}
.footer__topbar_menu {
  margin: 0px !important  ;
  display: grid;
  align-items: center;
}

.list-component li {
  list-style: none;
  padding-bottom: 15px;
  display: flex;
}
.list-component li div {
  padding-right: 10px;
}

.footer-services-title {
  letter-spacing: 1.2px !important;
  color: #2b303e;
}
.footer-services-title-hr {
  width: 77px;
  border: 2px solid #ff3535 !important;
  opacity: 1;
  margin: 10px 0px;
}
.li-text {
  list-style: none;
}
.li-text-alignment {
  text-align: left;
  letter-spacing: 0.8px !important;
  color: #2b303e !important;
  opacity: 1;
  cursor: pointer;
  text-decoration: none !important;
  line-height: 42px !important;
}

.li-icon-alignment {
  text-align: left;
  letter-spacing: 0px !important;
  color: #000000;
  opacity: 1;
  cursor: pointer;
}

.faq {
  text-align: left;
  letter-spacing: 0.32px !important;
  color: #a2a2a2 !important;
  opacity: 1;

  padding: 0px 20px;
  cursor: pointer;
}
.faq:hover {
  color: white !important;
}
.privacy-policy {
  text-align: left;
  letter-spacing: 0.32px !important;
  color: #a2a2a2;
  opacity: 1;
  padding: 0px 20px;
  cursor: pointer;
}
.privacy-policy:hover {
  color: white;
}
.footer__bottom {
  min-height: 97px;
  background: #1b1b1b 0% 0% no-repeat padding-box;
  opacity: 1;
  margin: 0px !important;
}
.font-bold-small {
  font-style: bold !important;
  font-variant: bold !important;
  font-size: 14px !important ;
  font-weight: 600 !important;
  line-height: 21px !important;
}

.copyrights {
  text-align: center;
  letter-spacing: 0.32px !important;
  opacity: 1;
  color: #a0a0a0 !important;
  display: flex;
}
.copyright-app {
  color: #ff3535 !important;
  cursor: pointer;
  padding-left: 3px;
}
.li-text-alignment:hover {
  cursor: pointer;
  color: #ff3535 !important;
}
#mrflogo-footer {
  margin-top: 5px;
  width: 280px;
  height: 57px;
}

.position-text {
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 10px 10px;
  opacity: 0.5;
  width: 220px;
  height: 60px;
  position: absolute;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-around;
}

#footer-layout-action {
  background: url("../../../../assets/images/track-me.png") no-repeat;
}
#footer-layout-action-2 {
  background: url("../../../../assets/images/emergency-help.png") no-repeat;
}
#footer-layout-action-3 {
  background: url("../../../../assets/images/family-chat.png") no-repeat;
}
.footer-layout {
  display: flex;
  align-items: flex-end;
  width: 220px;
  height: 140px;
  background-size: cover !important;
  border-radius: 10px;
}
