/* mobile devices */
@media only screen and (max-width: 639px) {
    .emergency-alert-container {
        display: flex;
        justify-content: center ;
        margin: auto;
        padding-right: 20px ;
        padding-left: 20px ;
         width: 100%;
      }
      .emergency-alert-form{
        padding: 0px !important;
        width: 280px !important;
        max-width: 380px !important;
      }
      .accordion-holder .MuiAccordion-root.MuiAccordion-rounded:before {
        display: none;
    }
    .accordion-holder .MuiAccordionSummary-root{
        max-height: 40px !important;
        min-height: 40px !important;
        background-color: #F5F5F5;
        border-radius: 5px;
       }
       .accordion-holder .MuiAccordion-root.MuiAccordion-rounded {
           box-shadow: none !important;
           margin-bottom: 20px;
       }
    .check-box-paddingleft{
        padding-left:0px ;
        margin: 10px 0px !important;
        /* align-items: flex-start !important; */
    }
    .check-box-error-paddingleft{
        padding-left:8% !important ;
    }
    .check-box-align{
        align-items: flex-start !important;
    }
    .checkboc-pt0{
        padding-top: 0px !important;
    }
    .check-box-content{
        display: flex;
        flex-direction: column !important;
        

    }
}

/* tablet devices */
@media only screen and (min-width: 768px) and (max-width: 999px) {
    .emergency-alert-container {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
      }
      .check-box-paddingleft{
        padding-left:60px ;
        margin: 7px 0px !important;
        /* align-items: center !important; */
    }
    .check-box-error-paddingleft{
        padding-left:74px !important ;
    }
    .check-box-content{
        display: flex;
        flex-direction: row;
        width: 80%;
        justify-content: space-between !important;   
    }
    .name-width{
        width: 30%;
    }
}

/*laptop devices */
@media only screen and (min-width: 1000px) {
    .emergency-alert-container {
        margin-left: auto;
        margin-right: auto;
        max-width: 95%;
        padding-left: 0;
        padding-right: 0;
        width: 95%;
      }
      .check-box-paddingleft{
        padding-left:100px ;
       
        /* align-items: center !important; */
    }
    .check-box-error-paddingleft{
        padding-left:122px !important ;
    }
    .check-box-content{
        display: flex;
        flex-direction: row;
        width: 80%;
        justify-content: space-between !important;   
    }
    .name-width{
        width: 40%;
    }
}

/* large devices */
@media only screen and (min-width: 1200px) {

    .emergency-alert-container {
        margin-left: auto;
        margin-right: auto;
        padding-right: 15px;
        padding-left: 15px;
        max-width: 95%;
        width: 1160px;
      }
      .check-box-paddingleft{
        padding-left:100px ;
        /* align-items: center !important; */
    }
    .check-box-content{
        display: flex;
        flex-direction: row;
        width: 80%;
        justify-content: space-between !important;   
    }
}
.check-box-align{
    align-items: center;
}