.vertical-tabs {
    align-items: flex-start;
    justify-content: flex-start;
    width: 23%;
}
/* .vertical-tabs .MuiTabs-scroller {
    width: 100%;
} */
.info-tabs {
    width: 74%;
}
.vertical-tabs button.MuiTab-root:first-child {
    border-top: 1px solid #AEAEAE;
}
.row-reverse {
    flex-direction: row-reverse !important;
    align-items: center !important;
}
.vertical-tabs button.MuiTab-root {
    align-items: self-start;
    border-bottom: 1px solid #AEAEAE;
    padding: 14px 10px ;
    text-transform: capitalize;
    color: #000;
    font-weight: 500 !important;
    min-height: 50px !important;
    justify-content: space-between;
}
.vertical-tabs .MuiTabs-scroller {
    width: 90%;
}
.vertical-tabs button.MuiTab-root:hover {
    border-bottom: 1px solid #ff3535;
    color: #ff3535;
    padding-left: 15px;
}
.vertical-tabs button.MuiTab-root.Mui-selected {
    color: #fff;
    background-color: #ff3535;
    padding: 14px 10px ;
}
.vertical-tabs .MuiTabs-indicator {
    display: none;
}
.action-bg {
    background: #F5F5F5;
    border: 1px solid #D8D8D8;
    padding:  23px;
    border-radius: 5px;
}
.pl-0 { 
    padding-left: 0 !important;
}
.flex-btm {
    justify-content: flex-end;
    display: flex;
    flex-direction: column !important;
}
.txt-area .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
}
.accordion-holder .MuiAccordionSummary-root{
 max-height: 40px !important;
 min-height: 40px !important;
 background-color: #F5F5F5;
 border-radius: 5px;
}
.accordion-holder .MuiAccordion-root.MuiAccordion-rounded {
    box-shadow: none !important;
    margin-bottom: 20px;
}
.flex-boxes {
    display: flex;
    gap: 10px;
    /* margin-left: -10px; */
}
.full-width {
    width: 100%;
}
.accordion-holder .MuiAccordion-root.MuiAccordion-rounded:before {
    display: none;
}
.h500 {
    height: 500px; 
    overflow-y: auto;
    width: 100%;
}
.mb-0 {
    margin-bottom: 0;
}
.pl-0 {
    padding-left: 0;
}
.delete-icon-btn {
    cursor: pointer;
}
.add-field-icon {
    background: url('../../../../assets/images/add.png') no-repeat;
    width: 20px;
    height: 20px;
    background-size: contain;
    margin-right: 10px;
}
.add-field-btn {
    margin-top: 10px !important;
}
.add-field-btn:hover .add-field-icon {
    background: url('../../../../assets/images/add-white.png') no-repeat;
    width: 20px;
    height: 20px;
    background-size: contain;
}
.security-input .MuiOutlinedInput-notchedOutline, .security-input .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
    border-color: transparent;
}
.security-input {
    border-radius: 10px;
}
.security-input .Mui-focused .MuiOutlinedInput-notchedOutline{
    border: 1px solid #C7CBCF !important;
    border-radius: 10px;
    box-shadow: 0px 2px 8px #00000029;
}
.security-input input {
    text-align: center;
    border-radius: 10px;
    border: 1px solid #C7CBCF !important;
}
.MuiStepIcon-root.Mui-completed {
    color: #3BC273 !important;
}
.MuiStepIcon-root.Mui-active {
    color: #FF3535 !important;
}
.MuiStepConnector-alternativeLabel span {
    border-width: 2px ;
}
.MuiStep-alternativeLabel.Mui-completed  .MuiStepConnector-alternativeLabel.Mui-completed  span, .MuiStep-alternativeLabel  .MuiStepConnector-alternativeLabel.Mui-active  span {
    border-color: #000;
    border-width: 2px ;
}
.MuiStepLabel-label.Mui-disabled span {
    color: #a2a2a2;
}
  

@media only screen and (max-width:767px) {
    .info-header-container {
      margin: auto;
      padding-left: 20px;
      padding-right: 20px;
  
      width: 100% !important;
    }
    .info{
        padding: 34px 0px !important;
        }

        .bigsize-device{
            display: none !important;
        }
        .smallsize-device{
            display: block !important; 
        }
        .flex-boxes {
            display: flex;
           flex-direction: column;
           margin-left: 0px;
           gap:0px
        }
        /* add-info */
        .profile-add-photo-dialog .MuiDialog-paper {
            height: 90%;
          }
          .profile-add-dialog-content{
            padding: 20px 21px 40px 21px !important;
            overflow-y: auto;
          }
          
  .profile-add-photo-upload-container{
    border: 2px dashed #ccc;
    /* height: 100%; */
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 30px;
    box-sizing: border-box;
    text-align: center;
    gap: 20px;
    cursor: pointer;
    margin-bottom: 30px;
  }
  }
  /* tablet */
  @media only screen and (min-width:768px) {
    .info-header-container {
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
    }
     .infocard-main-container{

        padding: 40px 0px !important;
       
        }
        .bigsize-device{
            display: none !important;
        }
        .smallsize-device{
            display: block !important; 
        }
         /* add-info */
         .profile-add-photo-dialog .MuiDialog-paper {
            height: 80%;
          }
          .profile-add-dialog-content{
            margin-bottom: 20px;
            padding: 20px 26px 20px 26px !important;
            overflow-y: auto;
        
          }
          .profile-add-photo-upload-container {
            border: 2px dashed #ccc;
            height: 100%;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 30px;
            box-sizing: border-box;
            text-align: center;
            gap: 20px;
            margin-bottom: 30px;
            cursor: pointer;
          }

  }
  /* laptop device */
  @media only screen and (min-width:1000px) {
    .info-header-container {
      margin-left: auto;
      margin-right: auto;
      max-width: 95%;
      padding-left: 0;
      padding-right: 0;
      width: 95%;
    }
    .bigsize-device{
        display: block !important;
    }
    .smallsize-device{
        display: none !important; 
    }
      /* add-info */
    .profile-add-photo-dialog .MuiDialog-paper {
        height: 600px;
        max-width: 650px;
      }
      .profile-add-dialog-content{
        padding: 20px 24px 5px 31px !important;
        margin-bottom: 0px;
        overflow-y: auto;
      }
  }
  /* large  device */
  @media only screen and (min-width: 1200px) {
    .info-header-container {
      padding-right: 15px;
      padding-left: 15px;
      margin-left: auto;
      margin-right: auto;
      max-width: 95%;
      width: 1150px !important;
    }
  }
  
  