.property-card{
  width: 100% !important;
  padding: 10px;
  height:fit-content
}
.property-container-top{
padding: 40px 0px;
}
/* mobile devices */
@media only screen and (max-width: 639px) {
    .my-property-container {
        /* display: flex; */
        justify-content: center ;
        margin: auto;
        padding-right: 20px ;
        padding-left: 20px ;
         width: 100%;
      }
      .property-card{
        width: 100% ;
        padding: 10px;
        height:fit-content
      }
      .property-form-padding{
        padding: 0 !important;
      }
      .item-padding{
        padding: 0px !important;
        min-width: 100%;
        
      }
      .upload-policy-width{
     width: 100%;
      }
      .property-accordian-padding{
        padding: 8px 0px 16px !important;
      }
      .upload-policy-div{
        width: 100%;
      }
      .quality-width{
        width: 100% !important;
      }
      .purchase-date-width{
        width: 100% !important;
      }
      .color-width{
        width: 100% !important;
      }
      .area-width{
        width: 100% !important;
      }
      .home-width{
        width: 100% !important;
      }
      .property-container-top{
        padding: 30px 0px;
        }
      .property-image-preview{
        object-fit:contain;
        width:185px;
        height:185px;
        margin-top: 10px;
      }
      .btn-height{
        height: 50px !important;
      }
      .MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-tooltip {
        transform-origin: center top;
        margin-top: 10px !important;
        margin-left: 15px !important;
    }
    .holder-margin{
      margin-top: 20px;
      margin-bottom: 30px;
    }
    .my-property-margin{
      margin: 20px 0px;
    }
}

/* tablet devices */
@media only screen and (min-width: 768px) and (max-width: 999px) {
    .my-property-container {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
      }
      .property-card{
        /* width: 300px; */
        padding: 10px;
        height:fit-content
      }
      .property-form-padding{
        padding: 20px;
      }
      .item-padding{
        padding:20px 0px 0px 16px !important ;
        min-width: 100%;
      }
      .upload-policy-width{
        width: 100%;
      }
      .property-accordian-padding{
        padding: 8px 0px 16px !important;
      }
      .upload-policy-div{
        width: 100%;
      }
      .property-image-preview{
        object-fit:contain;
        width:350px;
        height:185px 
      }
      .holder-margin{
        margin-top: 20px;
        margin-bottom: 30px;
      }
      .my-property-margin{
        margin: 20px 0px;
      }
}

/*laptop devices */
@media only screen and (min-width: 1000px) {
    .my-property-container {
        margin-left: auto;
        margin-right: auto;
        max-width: 95%;
        padding-left: 0;
        padding-right: 0;
        width: 95%;
      }
      .property-card{
        /* min-width: 340px; */
        padding: 10px;
        height:fit-content 
      }
      .property-form-padding{
        padding: 20px;
      }
      .item-padding{
        padding:32px;
      }
      .upload-policy-width{
        width: 492px;
      }
      .property-image-preview{
        object-fit:contain;
        width:350px;
        height:185px 
      }
      .holder-margin{
        margin-top: 0px;
        margin-bottom: 50px;
      }
      .my-property-margin{
        margin: 20px auto;
      }

}

/* large devices */
@media only screen and (min-width: 1200px) {

    .my-property-container {
        margin-left: auto;
        margin-right: auto;
        padding-right: 15px;
        padding-left: 15px;
        max-width: 95%;
        width: 1160px;
      }
      .property-card{
        /* min-width: 340px; */
        padding: 10px;
        height:fit-content 
      }
      .property-form-padding{
        padding: 20px;
      } 
      .item-padding{
        padding:32px;
      }
      .upload-policy-width{
        width: 492px;
      }
      .property-image-preview{
        object-fit:contain;
        width:350px;
        height:185px 
      }
      .holder-margin{
        margin-top: 0px;
        margin-bottom: 50px;
      }
      .my-property-margin{
        margin: 15px auto;
      }
}

.quality-width{
    width: 45%;
  }
  .purchase-date-width{
    width: 40%;
  }
  .color-width{
    width: 30%;
  }
  .area-width{
    width: 30%;
  }
  .home-width{
    width: 55%;
  }