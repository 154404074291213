.general-card:hover{ 
  border: 1px solid #ff3535 !important;
  cursor: pointer;
  box-shadow: 0px 2px 8px #00000033 !important;
 }
 /* mobile devices */
@media only screen and (max-width: 767px) {
  .MyInfo-header-container{
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100% !important;
  }
}
/* tablet devices */
@media only screen and (min-width: 768px) {
  .MyInfo-header-container{
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}

/* laptop devices */
@media only screen and (min-width: 1000px) {
  .MyInfo-header-container{
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
  }
}
/* largedevices */
@media only screen and (min-width: 1200px) {
  .MyInfo-header-container{
    padding-right: 15px;
    padding-left: 15px;
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    width: 1150px !important;
  }
}
