@media only screen and (max-width: 767px) {
  .emergency-container {
    width: 100%;
    margin: auto;
    padding-right: 20px;
    padding-left: 20px;
  }
  .box-container {
    padding: 0px !important;
    margin: 40px 0px;
  }
  .branding-box .MuiDialog-paper {
    width: 90% !important;
  }
  .emergency-img {
    width: 100% !important;
  }
  .emergency-save-btn{
    margin-top: 22px !important;
    margin-bottom: 43px !important;
  }
  .sub-text{
    padding-top: 10px;
  }
}
/* tablet devices */
@media only screen and (min-width: 768px) and (max-width: 999px) {
  .emergency-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .box-container {
    padding: 0px !important;
    margin: 50px 0px;
  }
  .emergency-img {
    width: 100% !important;
  }
  .save-btn{
    justify-content: space-evenly !important;
  }
  .sub-text{
    padding-top: 8px !important;
  }
}
/*laptop devices */
@media only screen and (min-width: 1000px) {
  .emergency-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
  }
  .box-container {
    padding: 0px !important;
    margin: 50px 0px;
  }
  .emergency-img {
    width: 350px !important;
  }
  .sub-text{
    padding-top: 15px !important;
  }
  
}
/* large devices */
@media only screen and (min-width: 1200px) {
  .emergency-container {
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 95%;
    width: 1160px;
  }
  .box-container {
    padding: 0px !important;
    margin: 50px 0px;
  }
  .emergency-img {
    width: 350px !important;
  }
  .sub-text{
    padding-top: 15px !important;
  }
}
