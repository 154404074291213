/* mobile devices */
@media only screen and (max-width: 639px) {
    .bank-accounts-container {
        display: flex;
        justify-content: center ;
        margin: auto;
        padding-right: 20px ;
        padding-left: 20px ;
         width: 100%;
      }
      .bank-card{
        width: 100% !important;
        padding: 10px;
      }
      .account-info-container{
        margin:0;
        padding: 0;
         width: 90%;
      }
    .bank-account-form{
        padding: 0px !important;
        width: 280px !important;
        max-width: 380px !important;
      }
      .account-info-padding{
        padding: 10px 18px !important;
      }
      .account-info-align{
        display: flex;
        flex-direction: column !important;
        padding: 8px 0px 16px !important;
      }
      .bank-account-margin{
        margin: 20px 0px;
      }
      /* .popup-button-gap{
        gap: 20px !important;
        display: flex;
        justify-content: space-evenly !important;
      } */
      .btn-holder-margin{
        margin-bottom: 50px;
      }
      .special-instruction-btn-holder{
        margin-top: 20px;
      }
}

/* tablet devices */
@media only screen and (min-width: 768px) and (max-width: 999px) {
    .bank-accounts-container {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
      }
      .bank-card{
        min-width: 338px !important;
        padding: 10px;
      }
      
      .account-info-container{
        margin:0;
        padding: 32px;
        width: 100%;
      }
      .account-info-align{
        display: flex;
        flex-direction: column !important;
        padding: 8px 0px 16px !important;
      }
      .bank-account-margin{
        margin: 20px 0px;
      }
      .btn-holder-margin{
        margin-top: 30px;
        margin-bottom: 30px;
      }
    
}


/*laptop devices */
@media only screen and (min-width: 1000px) {
    .bank-accounts-container {
        margin-left: auto;
        margin-right: auto;
        max-width: 95%;
        padding-left: 0;
        padding-right: 0;
        width: 95%;
      }
      .bank-card{
        min-width: 300px !important;
        padding: 10px;
      }
      .account-info-container{
        margin:0;
        padding: 32px;
        width: 100%;
      }
      .account-info-align{
        display: flex;
        flex-direction: row !important;
      }
      .bank-account-margin{
        margin: 20px auto;
      }
      .btn-holder-margin{
        margin-top: 30px;
        margin-bottom: 30px;
      }
}


/* large devices */
@media only screen and (min-width: 1200px) {

    .bank-accounts-container {
        margin-left: auto;
        margin-right: auto;
        padding-right: 15px;
        padding-left: 15px;
        max-width: 95%;
        width: 1160px;
      }
      .bank-card{
          min-width: 338px !important;
        padding: 10px;
      }
      .account-info-container{
        margin:0;
        padding: 32px;
        width: 100%;
      }
      .account-info-align{
        display: flex;
        flex-direction: row !important;
      }
      .bank-account-margin{
        margin: 15px auto;
      }
      .btn-holder-margin{
        margin-top: 30px;
        margin-bottom: 30px;
      }
}
.account-info-padding{
  padding: 20px;
}
/* .popup-button-gap{
  gap: 40px;
} */
