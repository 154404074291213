/*Mobile view*/
@media only screen and (max-width: 767px) {
  .track-me-container {
    width: 100%;
    margin: auto;
    padding-right: 20px;
    padding-left: 20px;
  }
  .track-me-label {
    margin-right: 0px !important;
    margin-bottom: -6px !important;
  }

  .location-card {
    padding: 15px 10px 30px 10px !important;
  }
   .location-card-1 {
    padding: 20px 0px 0px 0px !important;
  }
  .stop-tracking-btn {
    min-width: 100% !important;
  }
  /* .track-me-btn{
    justify-content: space-around !important;
  } */
  .map-container{
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
  }
  .request-tracking-box{
    padding-left: 0px !important;
    padding-top: 0px !important;
  }
  .box-container {
    padding: 0px !important;
    margin: 40px 0px;
  }
  .check-box-paddingleft{
    padding-left:0px ;
    margin: 10px 0px !important;
}
.check-box-error-paddingleft{
  padding-left:8% !important ;
}
  .track-me-now-map{
    padding-left: 30px !important;
  }
  .live-location-accordion{
    padding: 20px 0px !important;
  }
  .me-image{
    width: 100% !important;
  }
  .accordion-holder2{
    padding: 20px 10px 0px 10px !important;
  }
  .meeting-location {
    padding-left: 10px;
    padding-right: 10px;
}
.btn-box{
  justify-content: space-evenly !important;
  gap: 0px !important;
  padding: 0px !important;
}
.check-box-content{
  display: flex;
  flex-direction: column !important;
}
.check-box-align{
  align-items: flex-start !important;
}
.checkboc-pt0{
  padding-top: 0px !important;
}
}
/*Tablet view*/
@media only screen and (min-width: 768px) and (max-width: 999px) {
  .track-me-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .location-card {
    padding: 30px 10px 30px 10px;
  }
  .box-container {
    padding: 0px !important;
    margin: 50px 0px;
  }
  .stop-tracking-btn {
    min-width: 100% !important;
  }
  .track-me-label {
    margin-right: 0px !important;
    margin-bottom: -6px !important;
  }
  .live-location-accordion{
    padding: 20px 0px !important;
  }
  .map-container{
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
  }
  .check-box{
    padding-left: 40px !important;
  }
  .location-card-1 {
    padding: 20px 0px 0px 0px !important;
  }
  .track-me-now-map{
    padding-left: 30px !important;
  }
  .accordion-holder2{
    padding: 20px 10px 0px !important;
  }
  .meeting-location {
    padding-left: 10px;
    padding-right: 10px;
}
.btn-box{
  justify-content: space-evenly !important;
  gap: 0px !important;
  padding: 0px !important;
}
.check-box-paddingleft{
  padding-left:60px ;
  margin: 7px 0px !important;
  
}
.check-box-error-paddingleft{
  padding-left:74px !important ;
}
.check-box-content{
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-between !important;   
}
.name-width{
  width: 30%;
}
}
/*laptop devices */
@media only screen and (min-width: 1000px) {
  .track-me-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
  }
  .location-card {
    padding: 30px 10px 30px 10px !important;
  }
  .box-container {
    padding: 0px !important;
    margin: 50px 0px;
  }
  .stop-tracking-btn {
    min-width: 100% !important;
  }
  .stop-tracking-btn-box{
    padding-left: 10px;
  }
  .track-me-label {
    margin-right: 0px !important;
    margin-bottom: -6px !important;
  }
  .meeting-location{
    padding-left: 10px;
  }
  .live-location-accordion{
    padding: 20px 0px !important;
  }
  .map-container{
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
  }
  .check-box{
    padding-left: 11px;
  }
  .location-card-1 {
    padding: 20px 0px 0px 0px !important;
  }
  .track-me-now-map{
    padding-left: 30px !important;
  }
  .accordion-holder2{
    padding: 12px 10px 30px 10px !important;
}
.family-box{
  justify-content: flex-start !important;
}
.check-box-paddingleft{
  padding-left:100px !important ;
}
.check-box-error-paddingleft{
  padding-left:122px !important ;
}
.check-box-content{
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-between !important;   
}
.name-width{
  width: 40%;
}
}
/* large devices */
@media only screen and (min-width: 1200px) {
  .track-me-container {
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 95%;
    width: 1160px;
  }
  .location-card {
    padding: 30px 10px 30px 10px !important;
  }
  .box-container {
    padding: 0px !important;
    margin: 50px 0px;
  }
  .stop-tracking-btn {
    min-width: 100% !important;
  }
  .stop-tracking-btn-box{
    padding-left: 10px;
  }
  .track-me-label {
    margin-right: 0px !important;
    margin-bottom: -6px !important;
  }
  .meeting-location{
    padding-left: 10px;
  }
  .live-location-accordion{
    padding: 20px 0px !important;
  }
  .map-container{
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
  }
  .check-box{
    padding-left: 17px;
  }
  .location-card-1 {
    padding: 20px 0px 0px 0px !important;
  }
  .track-me-now-map{
    padding-left: 30px !important;
  }
  .accordion-holder2{
    padding: 12px 10px 30px 10 !important;
  }
  .family-box{
    justify-content: flex-start !important;
  }
  .check-box-paddingleft{
    padding-left:100px ;
   
}
.check-box-content{
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-between !important;   
}
.check-box-align{
  align-items: center;
}
}
