.emg-Image-services-container {
  display: flex;
  justify-content: flex-start;
  margin: 15px 0px 35px -12px !important;
  grid-column-gap: 30px !important;

  grid-row-gap: 20px;
}

/* mobile */
@media only screen and (max-width:767px) {
  .emergency-navbar-container {
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    width: 100% !important;
  }

  .emg-Image-services-container {
    display: flex;
    justify-content: center;
    margin: 12px 0px 12px !important;
    grid-row-gap: 0px;
  }

  .emg-Image-services {
    /* border-radius: 10px; */
    cursor: pointer;
    width: 194px;
    height: 184px;

  }

  .box-header-emergency {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 20px 0 0px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
  }

  .emergency-middle {
    padding-left: 0px;
  }

}

/* tablet */
@media only screen and (min-width:768px) {
  .emergency-navbar-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
  }

  .emg-Image-services {
    /* border-radius: 10px; */
    cursor: pointer;
    width: 194px;
    height: 184px;
  }

  .box-header-emergency {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 20px 0 0px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
  }
}

/* laptop */
@media only screen and (min-width:1000px) {
  .emergency-navbar-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    padding-left: 0;
    padding-right: 0;
    width: 95%;
  }

  .emergency-middle {
    padding-left: 20px;
  }

  .emg-Image-services {
    cursor: pointer;
  }
}

/* large devives */
@media only screen and (min-width:1200px) {
  .emergency-navbar-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
    width: 1150px !important;
  }
}