body {
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'Trajan Pro Regular';
  
  src: local('Trajan Pro Regular'), url('./fonts/TrajanPro-Regular.woff') format('woff');
  }
  

@font-face {
  font-family: 'Trajan Pro Bold';

  src: local('Trajan Pro Bold'), url('./fonts/TrajanPro-Bold.woff') format('woff');
  }
/* Small devices such as large phones (640px and up) */
@media only screen and (max-width: 767px) {
.primary-font-bold{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 46px !important ;
  font-weight: 700 !important;
  line-height: 60px !important;
}
.primary-font-bold1{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 20px !important ;
  font-weight: 700 !important;
  line-height: 26px !important;
}
.primary-font-bold-heading{
  font-style: bold !important;
  font-variant: bold !important;
  font-size: 50px !important ;
  font-weight: 700 !important;
  line-height: 66px !important;
}
.primary-font-bold-heading1{
  font-style: bold !important;
  font-variant: bold !important;
  font-size: 28px !important ;
  font-weight: 700 !important;
  line-height: 66px !important;
}
.primary-font-bold-heading2{
  font-style: bold !important;
  font-variant: bold !important;
  font-size: 16px !important ;
  font-weight: 700 !important;
  line-height: 66px !important;
}
.primary-font-default-heading{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 40px !important ;
  font-weight: 700 !important;
  line-height: 66px !important;
}
.primary-font-bold-read-more{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 18px !important ;
  font-weight: 700 !important;
  line-height: 26px !important;
}

.primary-font-bold-main-heading{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 16px !important ;
  font-weight: 700 !important;
  line-height: 27px !important;
  font-family: Trajan Pro Bold !important;
}

.primary-font-normal-plan-heading{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 80px !important ;
  font-weight: 300 !important;
  line-height: 106px !important;
}
.primary-font-normal-1{
  font-style: normal !important;
  font-variant: normal !important;
  font-size:16px !important ;
  font-weight: 400 !important;
  line-height: 42px !important;
}

.primary-font-normal-2{
  font-style: normal !important;
  font-variant: normal !important;
  font-size:16px !important ;
  font-weight: 400 !important;
  line-height: 26px !important;
}

.primary-font-normal-3{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 16px !important ;
  font-weight: 400 !important;
  line-height: 24px !important;
}

.primary-font-normal-4{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 16px !important ;
  font-weight: 400 !important;
  line-height: 21px !important;
}
.primary-font-normal-5{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 12px !important ;
  font-weight: 400 !important;
  line-height: 21px !important;
}
.primary-font-medium-small{
  font-style: medium !important;
  font-variant: medium !important;
  font-size: 16px !important ;
  font-weight: 400 !important;
  line-height: 21px !important;
}

.primary-font-bold-5{
  font-style: bold !important;
  font-variant: bold !important;
  font-size: 16px !important ;
  font-weight: 600 !important;
  line-height: 24px !important;
}
.font-normal-small .ant-list-item-meta-title .icon-room{
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 14px !important ;
  font-weight: 400 !important;
  line-height: 21px !important;
}
.font-normal-xsmall {
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 12px !important ;
  font-weight: 400 !important;
  line-height: 21px !important;
}
.font-bold-small {
  font-style: bold !important;
  font-variant: bold !important;
  font-size: 14px !important ;
  font-weight: 600 !important;
  line-height: 21px !important;
}

.primary-font-medium{ 
  font-style: medium !important;
  font-variant: medium !important;
  font-size:16px !important ;
  font-weight: 500 !important;
  line-height: 24px !important;
}
.primary-font-medium-2{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 16px !important ;
  font-weight: 500 !important;
  line-height: 24px !important;
}
.primary-font-medium-2-heading{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 18px !important ;
  font-weight: 500 !important;
  line-height: 33px !important;
  font-family: Trajan Pro Bold !important;
}
.primary-font-medium-3{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 21px !important ;
  font-weight: 500 !important;
  line-height: 30px !important;
}
.primary-font-medium-4{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 14px !important ;
  font-weight: 600 !important;
  line-height: 24px !important;
}
.primary-font-medium-5{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size:18px !important ;
  font-weight: 500 !important;
  line-height: 19px !important;
}
.primary-font-medium-6{ 
  font-style: medium !important;
  font-variant: medium !important;
  font-size:16px !important ;
  font-weight: 500 !important;
  line-height: 19px !important;
}
.primary-font-medium-7{ 
  font-style: medium !important;
  font-variant: medium !important;
  font-size:25px !important ;
  font-weight: 500 !important;
  line-height: 28px !important;
}
.primary-font-medium-8{ 
  font-style: normal !important;
  font-variant: normal !important;
  font-size: 12px !important ;
  font-family: Roboto !important ;
  font-weight: 400 !important;
  line-height: 27px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.primary-font-bold-6{
  font-style: bold !important;
  font-variant: bold !important;
  font-size: 18px !important ;
  font-weight: 600 !important;
  line-height: 21px !important;
}
.primary-font-bold-7{
  font-style: bold !important;
  font-variant: bold !important;
  font-size: 17px !important ;
  font-weight: 600 !important;
  line-height: 21px !important;
}
.primary-font-bold-8{
  font-style: bold !important;
  font-variant: bold !important;
  font-size: 12px !important ;
  font-weight: 600 !important;
  line-height: 24px !important;
}
.primary-font-actionbtn{
  font-style: bold !important;
  font-variant: bold !important;
  font-size: 13px !important ;
  font-weight: 600 !important;
  line-height: 16px !important;
}

.footer-navbar-font{
  font-style: normal !important;
  font-variant: normal !important;
  font-size:12px !important ;
  font-weight: 400 !important;
  line-height: 26px !important;
}
.MuiStepConnector-root{
  position: absolute !important;
    top: 6px !important;
    left: calc(-50% + 10px) !important;
    right: calc(50% + 10px) !important;
}
.MuiStep-root {
   padding-left: 0px !important; 
   padding-right: 0px !important;
}
.custom-stepper-mobile{
  display: block !important;
}
.custom-stepper-laptop{
  display: none !important;
}
.pricing-heading-font{
  font-size: 25px !important;
}
.pricing-features-font{
  font-size: 14px !important ;
}
.pricing-plan-font{
  font-size: 18px !important;
}

.error_wrap{
  width: 250px !important;
}
}

/* Medium devices such as tablets (768px and up) */
@media only screen and (min-width: 768px){
  .primary-font-bold{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 46px !important ;
    font-weight: 700 !important;
    line-height: 60px !important;
  }
  .primary-font-bold1{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 22px !important ;
    font-weight: 700 !important;
    line-height: 32px !important;
  }
  .primary-font-bold-heading{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 50px !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
  }
  .primary-font-bold-heading1{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 28px !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
  }
  .primary-font-bold-heading2{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 18px !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
  }
  .primary-font-default-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 40px !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
  }
  .primary-font-bold-read-more{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
  }
  
  .primary-font-bold-main-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 30px !important ;
    font-weight: 700 !important;
    line-height: 43px !important;
    font-family: Trajan Pro Bold !important;
  }
  
  .primary-font-normal-plan-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 80px !important ;
    font-weight: 300 !important;
    line-height: 106px !important;
  }
  .primary-font-normal-1{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-weight: 400 !important;
    line-height: 42px !important;
  }
  
  .primary-font-normal-2{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
  }
  
  .primary-font-normal-3{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-weight: 400 !important;
    line-height: 24px !important;
  }
  
  .primary-font-normal-4{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .primary-font-normal-5{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .primary-font-medium-small{
    font-style: medium !important;
    font-variant: medium !important;
    font-size: 16px !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  
  .primary-font-bold-5{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 16px !important ;
    font-weight: 600 !important;
    line-height: 21px !important;
  }
  .font-normal-small .ant-list-item-meta-title .icon-room{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .font-normal-xsmall {
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 12px !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .font-bold-small {
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 14px !important ;
    font-weight: 600 !important;
    line-height: 21px !important;
  }
  
  .primary-font-medium{ 
    font-style: medium !important;
    font-variant: medium !important;
    font-size:18px !important ;
    font-weight: 500 !important;
    line-height: 24px !important;
  }
  .primary-font-medium-2{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-weight: 500 !important;
    line-height: 24px !important;
  }
  .primary-font-medium-2-heading{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 22px !important ;
    font-weight: 500 !important;
    line-height: 33px !important;
    font-family: Trajan Pro Bold !important;
  }
  .primary-font-medium-3{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 21px !important ;
    font-weight: 500 !important;
    line-height: 30px !important;
  }
  .primary-font-medium-4{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-weight: 600 !important;
    line-height: 28px !important;
  }
  .primary-font-medium-5{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size:18px !important ;
    font-weight: 500 !important;
    line-height: 19px !important;
  }
  .primary-font-medium-6{ 
    font-style: medium !important;
    font-variant: medium !important;
    font-size:24px !important ;
    font-weight: 500 !important;
    line-height: 19px !important;
  }
  .primary-font-medium-7{ 
    font-style: medium !important;
    font-variant: medium !important;
    font-size:25px !important ;
    font-weight: 500 !important;
    line-height: 28px !important;
  }
  .primary-font-medium-8{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 27px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold-6{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 18px !important ;
    font-weight: 600 !important;
    line-height: 21px !important;
  }
  .primary-font-bold-7{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 17px !important ;
    font-weight: 600 !important;
    line-height: 21px !important;
  }
  .primary-font-bold-8{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 16px !important ;
    font-weight: 600 !important;
    line-height: 24px !important;
  }
  .primary-font-actionbtn{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 18px !important ;
    font-weight: 600 !important;
    line-height: 21px !important;
  }
  .footer-navbar-font{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:12px !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
  }
  .custom-stepper-mobile{
    display: none !important;
  }
  .custom-stepper-laptop{
    display: block !important;
  }
  .pricing-heading-font{
    font-size: 30px !important;
  }
  }

/* Largest devices such as laptops (1024px and up) */
@media only screen and (min-width: 1000px) {
  .primary-font-bold{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 46px !important ;
    font-weight: 700 !important;
    line-height: 60px !important;
  }
  .primary-font-bold1{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-weight: 700 !important;
    line-height: 42px !important;
  }
  .primary-font-bold-heading{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 50px !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
  }
  .primary-font-bold-heading1{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 28px !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
  }
  .primary-font-bold-heading2{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 28px !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
  }
  .primary-font-default-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 40px !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
  }
  .primary-font-bold-read-more{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
  }
  
  .primary-font-bold-main-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 30px !important ;
    font-weight: 700 !important;
    line-height: 43px !important;
    font-family: Trajan Pro Bold !important;
  }
  
  .primary-font-normal-plan-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 80px !important ;
    font-weight: 300 !important;
    line-height: 106px !important;
  }
  .primary-font-normal-1{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-weight: 400 !important;
    line-height: 42px !important;
  }
  
  .primary-font-normal-2{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
  }
  
  .primary-font-normal-3{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-weight: 400 !important;
    line-height: 24px !important;
  }
  
  .primary-font-normal-4{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .primary-font-normal-5{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .primary-font-medium-small{
    font-style: medium !important;
    font-variant: medium !important;
    font-size: 16px !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  
  .primary-font-bold-5{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 16px !important ;
    font-weight: 600 !important;
    line-height: 21px !important;
  }
  .font-normal-small, .ant-list-item-meta-title .icon-room{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .font-normal-xsmall {
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 12px !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .font-bold-small {
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 14px !important ;
    font-weight: 600 !important;
    line-height: 21px !important;
  }
  
  .primary-font-medium{ 
    font-style: medium !important;
    font-variant: medium !important;
    font-size:18px !important ;
    font-weight: 500 !important;
    line-height: 24px !important;
  }
  .primary-font-medium-2{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 20px !important ;
    font-weight: 500 !important;
    line-height: 24px !important;
  }
  .primary-font-medium-2-heading{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 22px !important ;
    font-weight: 500 !important;
    line-height: 33px !important;
    font-family: Trajan Pro Bold !important;
  }
  .primary-font-bold-2-heading{ 
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 22px !important ;
    font-weight: 500 !important;
    line-height: 33px !important;
    font-family: Trajan Pro Bold !important;
  }
  .primary-font-medium-3{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 21px !important ;
    font-weight: 500 !important;
    line-height: 30px !important;
  }
  .primary-font-medium-4{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 20px !important ;
    font-weight: 600 !important;
    line-height: 32px !important;
  }
  .primary-font-medium-5{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size:18px !important ;
    font-weight: 500 !important;
    line-height: 19px !important;
  }
  .primary-font-medium-6{ 
    font-style: medium !important;
    font-variant: medium !important;
    font-size:24px !important ;
    font-weight: 500 !important;
    line-height: 19px !important;
  }
  .primary-font-medium-7{ 
    font-style: medium !important;
    font-variant: medium !important;
    font-size:25px !important ;
    font-weight: 500 !important;
    line-height: 28px !important;
  }
  .primary-font-medium-8{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 27px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold-6{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 18px !important ;
    font-weight: 600 !important;
    line-height: 21px !important;
  }
  .primary-font-bold-7{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 17px !important ;
    font-weight: 600 !important;
    line-height: 21px !important;
  }
  .primary-font-bold-8{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 16px !important ;
    font-weight: 600 !important;
    line-height: 24px !important;
  }
  .primary-font-actionbtn{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 18px !important ;
    font-weight: 600 !important;
    line-height: 21px !important;
  }
  .footer-navbar-font{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
  }
  .custom-stepper-mobile{
    display: none !important;
  }
  .custom-stepper-laptop{
    display: block !important;
  }
  }


  /* Extra Largest devices such as desktops (1280px and up) */
@media only screen and (min-width: 1200px) {
  .primary-font-bold{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 46px !important ;
    font-weight: 700 !important;
    line-height: 60px !important;
  }
  .primary-font-bold1{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 26px !important ;
    font-weight: 700 !important;
    line-height: 42px !important;
  }
  .primary-font-bold-heading{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 50px !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
  }
  .primary-font-bold-heading1{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 28px !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
  }
  .primary-font-bold-heading2{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 28px !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
  }
  .primary-font-default-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 40px !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
  }
  .primary-font-bold-read-more{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
  }
  
  .primary-font-bold-main-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 30px !important ;
    font-weight: 700 !important;
    line-height: 43px !important;
    font-family: Trajan Pro Bold !important;
  }
  
  .primary-font-normal-plan-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 80px !important ;
    font-weight: 300 !important;
    line-height: 106px !important;
  }
  .primary-font-normal-1{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-weight: 400 !important;
    line-height: 42px !important;
  }
  
  .primary-font-normal-2{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
  }
  
  .primary-font-normal-3{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-weight: 400 !important;
    line-height: 24px !important;
  }
  
  .primary-font-normal-4{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .primary-font-normal-5{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .primary-font-medium-small{
    font-style: medium !important;
    font-variant: medium !important;
    font-size: 16px !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  
  .primary-font-bold-5{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 16px !important ;
    font-weight: 600 !important;
    line-height: 21px !important;
  }
  .font-normal-small, .ant-list-item-meta-title .icon-room{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .font-normal-xsmall {
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 12px !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .font-bold-small {
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 14px !important ;
    font-weight: 600 !important;
    line-height: 21px !important;
  }
  
  .primary-font-medium{ 
    font-style: medium !important;
    font-variant: medium !important;
    font-size:18px !important ;
    font-weight: 500 !important;
    line-height: 24px !important;
  }
  .primary-font-medium-2{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 20px !important ;
    font-weight: 500 !important;
    line-height: 24px !important;
  }
  .primary-font-medium-2-heading{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 22px !important ;
    font-weight: 500 !important;
    line-height: 33px !important;
    font-family: Trajan Pro Bold !important;
  }
  .primary-font-bold-2-heading{ 
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 22px !important ;
    font-weight: 500 !important;
    line-height: 33px !important;
    font-family: Trajan Pro Bold !important;
  }
  .primary-font-medium-3{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 21px !important ;
    font-weight: 500 !important;
    line-height: 30px !important;
  }
  .primary-font-medium-4{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 22px !important ;
    font-weight: 600 !important;
    line-height: 34px !important;
  }
  .primary-font-medium-5{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size:18px !important ;
    font-weight: 500 !important;
    line-height: 19px !important;
  }
  .primary-font-medium-6{ 
    font-style: medium !important;
    font-variant: medium !important;
    font-size:24px !important ;
    font-weight: 500 !important;
    line-height: 19px !important;
  }
  .primary-font-medium-7{ 
    font-style: medium !important;
    font-variant: medium !important;
    font-size:25px !important ;
    font-weight: 500 !important;
    line-height: 28px !important;
  }
  .primary-font-medium-8{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-family: Roboto !important ;
    font-weight: 400 !important;
    line-height: 27px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  }
  .primary-font-bold-6{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 18px !important ;
    font-weight: 600 !important;
    line-height: 21px !important;
  }
  .primary-font-bold-7{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 17px !important ;
    font-weight: 600 !important;
    line-height: 21px !important;
  }
  .primary-font-bold-8{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 16px !important ;
    font-weight: 600 !important;
    line-height: 24px !important;
  }
  .primary-font-actionbtn{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 18px !important ;
    font-weight: 600 !important;
    line-height: 21px !important;
  }
  .footer-navbar-font{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
  }
  .custom-stepper-mobile{
    display: none !important;
  }
  .custom-stepper-laptop{
    display: block !important;
  }

}

/* Extra Extra Largest devices such as desktops (1920px and up) */
/* @media only screen and (min-width: 120em) {
  .primary-font-bold{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 46px !important ;
    font-weight: 700 !important;
    line-height: 60px !important;
  }
  .primary-font-bold1{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 20px !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
  }
  .primary-font-bold-heading{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 50px !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
  }
  .primary-font-bold-heading1{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 28px !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
  }
  .primary-font-default-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 40px !important ;
    font-weight: 700 !important;
    line-height: 66px !important;
  }
  .primary-font-bold-read-more{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 18px !important ;
    font-weight: 700 !important;
    line-height: 26px !important;
  }
  
  .primary-font-bold-main-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 30px !important ;
    font-weight: 700 !important;
    line-height: 43px !important;
    font-family: Trajan Pro Bold !important;
  }
  
  .primary-font-normal-plan-heading{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 80px !important ;
    font-weight: 300 !important;
    line-height: 106px !important;
  }
  .primary-font-normal-1{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-weight: 400 !important;
    line-height: 42px !important;
  }
  
  .primary-font-normal-2{
    font-style: normal !important;
    font-variant: normal !important;
    font-size:16px !important ;
    font-weight: 400 !important;
    line-height: 26px !important;
  }
  
  .primary-font-normal-3{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-weight: 400 !important;
    line-height: 24px !important;
  }
  
  .primary-font-normal-4{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 16px !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .primary-font-medium-small{
    font-style: medium !important;
    font-variant: medium !important;
    font-size: 16px !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  
  .primary-font-bold-5{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 16px !important ;
    font-weight: 600 !important;
    line-height: 21px !important;
  }
  .font-normal-small, .ant-list-item-meta-title .icon-room{
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 14px !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .font-normal-xsmall {
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 12px !important ;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .font-bold-small {
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 14px !important ;
    font-weight: 600 !important;
    line-height: 21px !important;
  }
  
  .primary-font-medium{ 
    font-style: medium !important;
    font-variant: medium !important;
    font-size:18px !important ;
    font-weight: 500 !important;
    line-height: 24px !important;
  }
  .primary-font-medium-2{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 20px !important ;
    font-weight: 500 !important;
    line-height: 24px !important;
  }
  .primary-font-medium-2-heading{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 22px !important ;
    font-weight: 500 !important;
    line-height: 33px !important;
    font-family: Trajan Pro Bold !important;
  }
  .primary-font-bold-2-heading{ 
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 22px !important ;
    font-weight: 500 !important;
    line-height: 33px !important;
    font-family: Trajan Pro Bold !important;
  }
  .primary-font-medium-3{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 21px !important ;
    font-weight: 500 !important;
    line-height: 30px !important;
  }
  .primary-font-medium-4{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size: 22px !important ;
    font-weight: 600 !important;
    line-height: 34px !important;
  }
  .primary-font-medium-5{ 
    font-style: normal !important;
    font-variant: normal !important;
    font-size:18px !important ;
    font-weight: 500 !important;
    line-height: 19px !important;
  }
  .primary-font-medium-6{ 
    font-style: medium !important;
    font-variant: medium !important;
    font-size:24px !important ;
    font-weight: 500 !important;
    line-height: 19px !important;
  }
  .primary-font-bold-6{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 18px !important ;
    font-weight: 600 !important;
    line-height: 21px !important;
  }
  .primary-font-bold-7{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 17px !important ;
    font-weight: 600 !important;
    line-height: 21px !important;
  }
  .primary-font-bold-8{
  font-style: bold !important;
  font-variant: bold !important;
  font-size: 16px !important ;
  font-weight: 600 !important;
  line-height: 24px !important;
}
.primary-font-actionbtn{
    font-style: bold !important;
    font-variant: bold !important;
    font-size: 18px !important ;
    font-weight: 600 !important;
    line-height: 21px !important;
  }
} */

.default-font{
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
  
/* .header-panel{
  min-height: 200px;
  background: transparent linear-gradient(180deg, #CFD5C7 0%, #F7EBDA 65%, #FEF2E1 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
} */
#activate-emergency-alert:hover{
  cursor: pointer;
}
.general-card:hover{ 
  border: 1px solid #ff3535 !important;
  cursor: pointer;
  box-shadow: 0px 2px 8px #00000033 !important;
  border-radius: 10px !important;
 cursor: pointer;
}
.ant-list-item:hover:has(ant-list-item-meta-title){
  color: #ff3535 !important;
  
}
.ant-list-item .ant-list-item-meta-title:hover{
  color: #ff3535 !important;
}
.box-container {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0;
  background-color: #fff;
  box-shadow: 0px 1px 12px #00000026;
}
.box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 20px 0 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
}
.font-family-default{
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.box-header p {
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.box-header-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px 20px 0 20px;
  /* border-bottom: 1px solid #ccc; */
  padding-bottom: 20px;
}
.box-header-2 p {
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

.go-back {
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}
.red-font {
  color: #ff3535;
}
.input-gap {
  margin-bottom: 50px;
}
@media only screen and (max-width: 585px) { 
  #topbar-menu-content {
    flex-wrap: wrap !important;
    justify-content: center !important;
  }
}
.p0 {
  padding: 0 !important;
}
.m0 {
  margin: 0 !important;
}

.ant-list-empty-text{
  display: none;
}

.MuiBadge-badge{
  margin-top: 15px;
  margin-right: 15px;
}

.dot{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ff3535;
  display: inline-block;
  float: inline-end;
}
.custom-radio-button {
  display: inline-block;
  vertical-align: text-bottom;
  margin-left: 5px;
  cursor: pointer;
}
img[src$="#custom_marker"]{
  border: 2px solid #FF3535 !important;
  border-radius:50%;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.gm-ui-hover-effect{
  top: 4px !important;
  right: 0px !important;
  padding-left: 6px !important;
}
.gm-style-iw{
  padding-inline-end: revert-layer !important
}

.recorder-circle{
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  border: 1px solid #00000029;
  box-shadow: 0px 2px 8px #00000029;
}

.scrollable-container {
  height: 400px; 
  overflow: auto;
  /* Hide the scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */

}

/* Hide the scrollbar for WebKit based browsers (Chrome, Safari, etc.) */
.scrollable-container::-webkit-scrollbar {
  display: none;
}
.emg-Image{
  cursor: pointer;
}

.no-data-container {
  display: grid;
  justify-content: center; 
  align-items: center; 
  /* height: 100vh; */
  margin-top: 50px;
}
.no-data-text{
  color: #ddd;
  font-size: 24px;
}
.image-multi-container {
  width: 100%;
  height: 140px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
}
.track-img{
  width: 300px ;
 cursor: pointer;
 border: 1px solid #FFF;
}

.track-img:hover{
  border: 1px solid #FF3535;
  border-radius: 13px;
}

.image-name{
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
  text-overflow: ellipsis;
  margin: 6px 4px 2px 4px;
  font-size: 13px;
}
.css-tlphzd-MuiCardContent-root:last-child {
  all: unset;
}
.scrollable-element {
  height: 200px; 
  overflow-y: auto; 
  scrollbar-width: thin; 
  scrollbar-color: #EEEEEE;
  /* scrollbar-arrow-color: transparent; */
}

/* For WebKit-based browsers */
.scrollable-element::-webkit-scrollbar {
  width: 2px; 
}

/* For WebKit-based browsers */
.scrollable-element::-webkit-scrollbar-thumb {
  background-color: rgba(255, 0, 0, 0.8); 
}

/* For WebKit-based browsers */
.scrollable-element::-webkit-scrollbar-button {
  background-color: rgba(255, 0, 0, 0.8); 
}

.image-name {
  position: relative;
}

/* .edit-icons {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
} */

.edit-icon,
.tick-icon {
  cursor: pointer;
  margin-left: 5px;
}

.image-name input[type='text'] {
  border: none;
  outline: none;
  border-bottom: 1px solid #ddd;
  width: 80%;
  padding: 0;
  padding-bottom: 6px;
}
.gm-style-iw-ch{
  justify-content: flex-end !important;
}
.gm-style-iw-chr{
  justify-content: flex-end !important;
}
.gm-ui-hover-effect{
  height: 25px !important;
  width: 48px !important;
}
/* Payments */

.apply-amount {
  position: relative;
}

.amount-input {
  height: 48px;
  width: 100%;
  padding: 16px;
  border-radius:4px;
  box-sizing: border-box;
  border: 1px solid #e6e6e6 !important;
  outline: none;
  font-size: 14px !important;
  /* font-family: 'Albert Sans'; */
}

.placeholder-text::placeholder {
  text-transform: none !important;
}  
.sub-head {
  font-size: 14px !important;
  line-height: 14px !important;
  font-weight: 700 !important;
  /* font-family: 'Albert Sans' !important; */
  /* letter-spacing: -0.05em !important; */
}
.normal-text, .receipt-label .MuiFormControlLabel-label {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  /* font-family: 'Albert Sans' !important; */
  /* letter-spacing: -0.05em !important; */
}
.apply-btn {
  position: absolute !important;
  right: 5px;
  top: 7px;
  height: 34px;
  /* background-color: #ADADAD !important; */
  background-color: #FFF !important;
  padding: 10px 16px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: none !important;
  border: 1px solid #FF3535 !important;
  color:#FF3535 !important
}
.outline-button {
  background-color: #FFF !important;
  padding: 10px 16px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: none !important;
  border: 1px solid #FF3535 !important;
  color:#FF3535 !important
}
.apply-btn:hover, .outline-button:hover{
  background-color: #FF3535 !important;
  color:#FFF !important

}
.custom-button.Mui-disabled {
  opacity: 0.5;
}
.mb-10 {
  margin-bottom: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.flex-space-btw {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}


.next-btn {
  height: 46px;
  background-color: #FF3535 !important;
  padding: 24px 32px;
  box-sizing: border-box;
  border-radius: 6px !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  text-transform: capitalize !important;
  width: 100%;
  box-shadow: none !important;
}

.progress-color {
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px !important;
  color: white !important;
}


.payment-disabled {
  opacity: 0.5;
}

._success {
  box-shadow: 0 15px 25px #00000019;
  padding: 45px;
  /* width: 100%; */
  text-align: center;
  margin: 40px auto;
  border-bottom: solid 4px #28a745;
}

._failed{ 
  border-bottom: solid 4px red !important; 
}
.promo-wrapper{
  border: 1px solid green;
  padding: 10px 16px;
  border-radius: 4px;
}
.payment-input{
  border: 1px solid #df1b41 !important;
}
.error_wrap{
  width: 412px;
}
.w-50{
  width: 50%;
}
.font-normal {
  text-transform: capitalize;
  font-weight: normal !important;
}