.create-account .react-date-picker__button__icon {
    stroke: grey;
    margin-right: 10px;
  }
  .create-account .react-date-picker{
    position: static !important;
    background: snow;
  }

 .create-account .react-date-picker__inputGroup div:empty::before {
    content: 'fallback placeholder';
  }