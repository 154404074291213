@media only screen and (max-width: 767px) {
    .message-container {
     width: 100%;
      margin: auto;
      padding-right: 20px;
      padding-left: 20px;
    }
    .box-container {
      padding: 0px !important;
      margin: 40px 0px;
    }
    .message-card {
      padding: 10px 0px 0px 0px !important;
    }
    .predefined-box .MuiDialog-paper {
      width: 90% !important;
      padding: 15px 0px !important;
    }
    .predefined-box .MuiDialogTitle-root{
      padding: 0px 30px !important;
    }
    .predefined-box .MuiDialogContent-root {
      padding: 30px 30px 0 30px !important;
  }
  /* .cancel-save-btn{
    margin:10px 20px 0px 20px  !important;
    
  } */
}
/* tablet devices */
@media only screen and (min-width: 768px) and (max-width: 999px) {
    .message-container {
      margin-left: auto ;
      margin-right: auto ;
      max-width: 100% ;
      padding-left: 20px ;
      padding-right: 20px ;
      width: 100% ;
      
    }
    .box-container {
      padding: 0px !important;
      margin: 50px 0px;
    }
    .message-card {
      padding: 10px 0px 0px 0px !important;
    }
    .predefined-box .MuiDialog-paper {
      padding: 15px 0px !important;
    }
    .predefined-box .MuiDialogTitle-root{
      padding: 0px 30px !important;
    }
    .predefined-box .MuiDialogContent-root {
      padding: 30px 30px 0 30px !important;
  }
  /* .cancel-save-btn{
    margin: 0px 30px !important;
    justify-content: space-between !important;
  } */
}
/*laptop devices */
@media only screen and (min-width: 1000px) {
    .message-container {
      margin-left: auto ;
      margin-right: auto ;
      max-width: 95% ;
      padding-left: 0 ;
      padding-right: 0 ;
      width: 95% ;
     
    }
    .box-container {
      padding: 0px !important;
      margin: 50px 0px;
    }
    .message-card {
      padding: 10px 0px 0px 0px !important;
    }
    .predefined-box .MuiDialog-paper {
      padding: 15px 0px !important;
    }
    .predefined-box .MuiDialogTitle-root{
      padding: 0px 30px !important;
    }
    .predefined-box.MuiDialogContent-root {
      padding: 30px 30px 0 30px !important;
  }
  /* .cancel-save-btn{
    margin: 0px 30px !important;
    justify-content: space-between !important;
  } */
}
/* large devices */
@media only screen and (min-width: 1200px) {
    .message-container {
      margin-left: auto ;
      margin-right: auto ;
      padding-right: 15px ;
      padding-left: 15px ;
      max-width: 95% ;
      width: 1160px ;
     
    }
    .box-container {
      padding: 0px !important;
      margin: 50px 0px;
    }
    .message-card {
      padding: 10px 0px 0px 0px !important;
    }
    .predefined-box .MuiDialog-paper {
      padding: 15px 0px !important;
    }
    .predefined-box .MuiDialogTitle-root{
      padding: 0px 30px !important;
    }
    .predefined-box .MuiDialogContent-root {
      padding: 30px 30px 0 30px !important;
  }
  /* .cancel-save-btn{
    margin: 0px 30px !important;
    justify-content: space-between !important;
  } */
}